import client from '../request'

const getAll = `/notifications`

const get = (id) => {
  return `/notifications/${id}`
}

const create = (data) => {
  return client.post('/notifications', data)
}

const update = (id, data) => {
  return client.put(`/notifications/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data?.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/notifications/${id}`)
}

const NotificationService = {
  getAll,
  get,
  create,
  update,
  toRow,
  remove,
  toPaginate
}

export default NotificationService
