import client from '../request'

const create = (data) => {
  return client.post('/reports', data)
}

const ReportService = {
  create
}

export default ReportService
