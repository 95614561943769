import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { Card, Col, Form, Input, Divider, Row, Select, message, InputNumber, Button } from 'antd'
import { isEmpty, some } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { ChromePicker } from 'react-color'

import VideoService from '../../network/services/video'
import SubtitleService from '../../network/services/subtitle'

import Gaps from '../../components/Gaps'
import VideoSearch from '../../components/VideoSearch'
import VideoPreview from './VideoPreview'
import useSWR from 'swr'

const { Option } = Select

const SubtitleForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const [color, setColor] = useState(initialValue?.style?.color)
  const [intervalInput, setIntervalInput] = useState(0.5)
  const [startInput, setStartInput] = useState(initialValue?.details?.[0].after ?? 0)
  const [videoId, setVideoId] = useState(initialValue?.video_id ?? null)
  const [subtitles, setSubtitles] = useState([])

  const handleIntervalChange = (value) => {
    setIntervalInput(value)
  }

  const handleStartSecondChange = (value) => {
    setStartInput(value)
  }

  const handleGenerateClick = () => {
    let text = form.getFieldValue('text')
    let startValue = startInput

    // NOTE: Skip first iteration as first iteration is the starting second
    const reduceArr = Array(text.split(' ').length - 1).fill(intervalInput)

    const sum = reduceArr.reduce((accumulator, currentValue) => {
      accumulator.push((startValue += currentValue))
      return accumulator
    }, [])
    const sumCopy = [startInput, ...sum]
    for (let index = 0; index < text.split(' ').length; index++) {
      form.setFieldsValue({
        details: {
          [index]: {
            text: text.split(' ')[index],
            after: sumCopy[index]
          }
        }
        // ['details']: {
        //   [index]: {
        //     ['text']: text.split(' ')[index],
        //     ['after']: sumCopy[index]
        //   }
        // }
      })
    }
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    console.log(values)

    const style = {
      color: color,
      font_type: values['font_type'],
      font_size: values['font_size'],
      top: values['top'],
      // bottom: values['bottom'],
      left: values['left'],
      right: values['right'],
      opacity: values['opacity']
    }

    const element = {
      details: values['details'],
      style: style,
      text: values['text'],
      video_id: values['video_id'],
      enter: values['enter'],
      exit: values['exit'],
      before: values['before']
    }

    if (initialValue?.id) {
      // ToDo: update text not working
      try {
        const { data: result } = await SubtitleService.update(initialValue.id, element)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update subtitle error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await SubtitleService.create(element)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/subtitles/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create subtitle error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const { data: videoResponse } = useSWR(videoId != null ? VideoService.get(videoId) : null)
  const video = videoResponse?.data

  const handleGenerateVideoSubtitle = async () => {
    const subtitles = []
    const enter = form.getFieldValue(['enter'])
    const before = form.getFieldValue(['before'])
    const exit = form.getFieldValue(['exit'])
    const top = form.getFieldValue(['top'])
    const left = form.getFieldValue(['left'])
    const font_type = form.getFieldValue(['font_type'])
    const font_size = form.getFieldValue(['font_size'])

    let data = await form.validateFields()
    if (data?.details != null) {
      data.details.forEach((item) => {
        subtitles.push({
          text: item.text,
          enter,
          exit,
          before,
          top,
          left,
          font_type,
          font_size,
          color: color,
          after: item.after
        })
      })

      setSubtitles(subtitles)
    }
  }

  useEffect(() => {
    if (initialValue?.video_id != null) {
      handleGenerateVideoSubtitle()
    } else {
      setSubtitles([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-subtitle"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          color: initialValue?.style?.color,
          font_type: initialValue?.style?.font_type,
          font_size: initialValue?.style?.font_size,
          top: initialValue?.style?.top,
          // bottom: initialValue?.style?.bottom,
          left: initialValue?.style?.left,
          right: initialValue?.style?.right,
          opacity: initialValue?.style?.opacity,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
        onValuesChange={(values) => {
          if (Object.keys(values).includes('video_id')) {
            setVideoId(values.video_id)
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item
                name="text"
                label="Sentence"
                rules={[{ required: true, message: 'Please insert subtitle' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="enter"
                label="Time to insert into the video"
                rules={[
                  {
                    required: true,
                    message: 'Please insert subtitle enter time'
                  }
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="before"
                label="Time to fade in"
                rules={[
                  {
                    required: true,
                    message: 'Please insert subtitle highlight time'
                  }
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="exit"
                label="Time to fade out and remove from video"
                rules={[
                  {
                    required: true,
                    message: 'Please insert subtitle exit time'
                  }
                ]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Card>

            <Gaps />

            <Card title="Style">
              <Form.Item name="font_type" label="Font for subtitle">
                <Select>
                  <Option value="fredoka_one">Fredoka One</Option>
                  <Option value="baloo_2">Baloo 2</Option>
                </Select>
              </Form.Item>
              <Form.Item name="font_size" label="Font Size for subtitle">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="color" label="Font color for subtitle">
                <ChromePicker
                  color={color}
                  onChange={(color, event) => {
                    setColor(color.hex)
                  }}
                />
              </Form.Item>
              <Form.Item name="top" label="Font top position for subtitle ( % )">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="left" label="Font left position for subtitle ( % )">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="right" label="Font right position for subtitle ( % )">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="opacity" label="Font opacity for subtitle ( % )">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Card>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} lg={{ span: 12 }}>
            <Card bordered={false}>
              <VideoSearch
                label="Video"
                name="video_id"
                searchKey="title"
                //defaultValue={initialValue.video_id}
                rules={[{ required: true, message: 'Please select a video!' }]}
              />

              <Divider>Time to highlight subtitle text</Divider>

              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item label="Interval">
                    <InputNumber
                      step="0.5"
                      value={intervalInput}
                      onChange={handleIntervalChange}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col span={16}>
                  <Form.Item label="Start Second">
                    <Row gutter={8}>
                      <Col span={12}>
                        <InputNumber
                          value={startInput}
                          onChange={handleStartSecondChange}
                          style={{ width: '100%' }}
                        />
                      </Col>
                      <Col offset={2} span={10}>
                        <Button onClick={handleGenerateClick}>Generate</Button>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item noStyle dependencies={['text']}>
                {(form) => {
                  const inputs = form.getFieldValue('text')
                  return inputs?.split(' ').map((elem, index) => {
                    return (
                      <>
                        <Form.Item name={[`details`, index, `text`]} readOnly hidden>
                          <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                          name={[`details`, index, `after`]}
                          fieldKey={[`details`, index, `after`]}
                          label={`${elem}`}
                          rules={[
                            {
                              required: true,
                              message: 'Please insert subtitle unhighlight time'
                            }
                          ]}
                        >
                          <InputNumber ref={`input`} style={{ width: '100%' }} />
                        </Form.Item>
                      </>
                    )
                  })
                }}
              </Form.Item>
            </Card>

            <Gaps />

            <Card
              title="Video Preview"
              bordered={false}
              extra={<Button onClick={handleGenerateVideoSubtitle}>Generate</Button>}
            >
              <VideoPreview currentVideo={video} subtitles={subtitles} />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default SubtitleForm
