import { Suspense } from 'react'
import { Space, Spin } from 'antd'
import { Routes, BrowserRouter, Route, Navigate } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import './App.less'
import authStore, { checkAuthorization } from './lib/store/auth'
import Dashboard from './pages/Dashboard'
import SignIn from './pages/auth/SignIn'

import StoryPage from './pages/stories'
import StoryDetail from './pages/stories/StoryDetail'
import SubtitlePage from './pages/subtitles'
import SubtitleDetail from './pages/subtitles/SubtitleDetail'
import ChapterPage from './pages/chapters'
import ChapterDetail from './pages/chapters/ChapterDetail'
import VideoPage from './pages/videos'
import VideoDetail from './pages/videos/VideoDetail'
import AuthenticatedLayout from './layout/AuthenticatedLayout'
import PlaylistPage from './pages/playlists'
import PlaylistDetail from './pages/playlists/PlaylistDetail'
import UserPage from './pages/users'
import UserDetail from './pages/users/UserDetail'
import PlanPage from './pages/plans'
import PlanDetail from './pages/plans/PlanDetail'
import SubscriptionPage from './pages/subscriptions'
import SubscriptionDetail from './pages/subscriptions/SubscriptionDetail'
import ActivityPage from './pages/activities'
import ActivityDetail from './pages/activities/ActivityDetail'
import RecordingPage from './pages/recordings'
import RecordingDetail from './pages/recordings/RecordingDetail'
import FeedbackPage from './pages/feedbacks'
import FeedbackDetail from './pages/feedbacks/FeedbackDetail'
import LoginActivityPage from './pages/login_activities'
import ScannerPage from './pages/scanner'
import ScannerDetail from './pages/scanner/ScannerDetail'
import ReviewPage from './pages/reviews'
import ReviewDetail from './pages/reviews/ReviewDetail'
import SettingPage from './pages/settings'
import SettingDetail from './pages/settings/SettingDetail'
import UserGroupPage from './pages/user_groups'
import UserGroupDetail from './pages/user_groups/UserGroupDetail'
import PackPage from './pages/packs'
import PackDetail from './pages/packs/PackDetail'
import PushNotificationsPage from './pages/push_notifications'
import PushNotificationDetail from './pages/push_notifications/PushNotificationDetail'
import SlidePage from './pages/slides'
import SlideDetail from './pages/slides/SlideDetail'
import VersionsPage from './pages/versions'
import VersionDetail from './pages/versions/VersionDetail'

function PrivateRoute({ children, redirectTo }) {
  const {
    state: { token: isLoggedIn }
  } = useSnapshot(authStore)

  return isLoggedIn ? children : <Navigate to={redirectTo} />
}

function App() {
  return (
    <Suspense
      fallback={
        <Space align="center" style={{ width: '100vw', height: '100vh', justifyContent: 'center' }}>
          <Spin />
        </Space>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute redirectTo="/">
                <AuthenticatedLayout />
              </PrivateRoute>
            }
          >
            <Route exact path="" element={<Dashboard />} />
            <Route exact path="stories" element={<StoryPage />} />
            <Route exact path="stories/:id" element={<StoryDetail />} />
            <Route exact path="subtitles" element={<SubtitlePage />} />
            <Route exact path="subtitles/:id" element={<SubtitleDetail />} />
            <Route exact path="chapters" element={<ChapterPage />} />
            <Route exact path="chapters/:id" element={<ChapterDetail />} />
            <Route exact path="videos" element={<VideoPage />} />
            <Route exact path="videos/:id" element={<VideoDetail />} />
            <Route exact path="playlists" element={<PlaylistPage />} />
            <Route exact path="playlists/:id" element={<PlaylistDetail />} />
            <Route exact path="plans" element={<PlanPage />} />
            <Route exact path="plans/:id" element={<PlanDetail />} />
            <Route exact path="subscriptions" element={<SubscriptionPage />} />
            <Route exact path="subscriptions/:id" element={<SubscriptionDetail />} />
            <Route exact path="activities" element={<ActivityPage />} />
            <Route exact path="activities/:id" element={<ActivityDetail />} />
            <Route exact path="recordings" element={<RecordingPage />} />
            <Route exact path="recordings/:id" element={<RecordingDetail />} />
            <Route exact path="feedbacks" element={<FeedbackPage />} />
            <Route exact path="feedbacks/:id" element={<FeedbackDetail />} />
            <Route exact path="users" element={<UserPage />} />
            <Route exact path="users/:id" element={<UserDetail />} />
            <Route exact path="login_activities" element={<LoginActivityPage />} />
            <Route exact path="scanners" element={<ScannerPage />} />
            <Route exact path="scanners/:id" element={<ScannerDetail />} />
            <Route exact path="reviews" element={<ReviewPage />} />
            <Route exact path="reviews/:id" element={<ReviewDetail />} />
            <Route exact path="settings" element={<SettingPage />} />
            <Route exact path="settings/:id" element={<SettingDetail />} />
            <Route exact path="user-groups" element={<UserGroupPage />} />
            <Route exact path="user-groups/:id" element={<UserGroupDetail />} />
            <Route exact path="packs" element={<PackPage />} />
            <Route exact path="packs/:id" element={<PackDetail />} />
            <Route exact path="slides" element={<SlidePage />} />
            <Route exact path="slides/:id" element={<SlideDetail />} />
            <Route exact path="notifications" element={<PushNotificationsPage />} />
            <Route exact path="notifications/:id" element={<PushNotificationDetail />} />
            <Route exact path="versions" element={<VersionsPage />} />
            <Route exact path="versions/:id" element={<VersionDetail />} />
          </Route>

          <Route path="/sign-in" exact element={<SignIn />} />
          <Route path="/" exact element={<SignIn />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}

new Promise(() => {
  checkAuthorization()
}).catch((error) => console.error(error))

export default App
