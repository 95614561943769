import React, { useState } from 'react'
import { Button, Card, PageHeader, Space, Typography, message } from 'antd'
import ReportService from '../network/services/report'

const Dashboard = () => {
  const [isBusy, setIsBusy] = useState(false)

  const generateTWReport = async () => {
    setIsBusy(true)

    try {
      await ReportService.create({ region: 'tw' })
      message.success('Synchronised to Google Sheet sucessfully')
    } catch (error) {
      console.log(error)
    }

    setIsBusy(false)
  }

  const generateRRReport = async () => {
    setIsBusy(true)

    try {
      await ReportService.create({ region: 'sg' })
    } catch (error) {
      console.log(error)
    }

    setIsBusy(false)
  }

  return (
    <PageHeader title="Dashboard">
      <Card title="Reports">
        <Space direction="vertical">
          <Typography.Text>Generate report to Google Sheets</Typography.Text>
          <Space>
            <Space direction="vertical">
              <a
                href="https://docs.google.com/spreadsheets/d/1sWdR1SnJgfbRivjTGBKJMCx98XJrz9__k4KW7diQGew/edit#gid=1386832825"
                target="_blank"
                rel="noreferrer"
              >
                Google Sheet
              </a>
              <Button type="primary" loading={isBusy} onClick={generateTWReport}>
                Generate for Taiwan
              </Button>
            </Space>

            <Space direction="vertical">
              <Button type="primary" loading={isBusy} onClick={generateRRReport} disabled>
                Generate for the rest of the world
              </Button>
            </Space>
          </Space>
        </Space>
      </Card>
    </PageHeader>
  )
}

export default Dashboard
