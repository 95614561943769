import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Select, message } from 'antd'

import SlideService from '../../network/services/slide'

import Gaps from '../../components/Gaps'
import ThumbnailUpload from '../../components/ThumbnailUpload'
import FileService from '../../network/services/file'

const SlideForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()

  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const { data: upload } = await FileService.create(currentImage, { type: 'image' })
      values['thumbnail_url'] = upload.data
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await SlideService.update(initialValue.id, values)

        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update slide error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await SlideService.create(values)

        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/slides/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create slide error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-slide"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item name="cta_url" label="CTA">
                <Input />
              </Form.Item>

              <Gaps />

              <Form.Item
                name="region"
                label="Region"
                rules={[{ required: true, message: 'Please select the region' }]}
              >
                <Select>
                  <Select.Option value="sg">Singapore</Select.Option>
                  <Select.Option value="tw">Taiwan</Select.Option>
                </Select>
              </Form.Item>
            </Card>

            <Gaps />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <ThumbnailUpload
              initialValue={{ thumbnail_url: initialValue?.thumbnail_url }}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default SlideForm
