import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import 'react-quill/dist/quill.core.css'
import FileService from '../network/services/file'

const RichEditor = ({ form, name }) => {
  const quillRef = useRef()
  const imageHandler = () => {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()

    input.onchange = async () => {
      // Save current cursor state
      const range = quillRef.current.getEditor().getSelection(true)

      // Insert temporary loading placeholder image
      quillRef.current
        .getEditor()
        .insertEmbed(range.index, 'image', `https://via.placeholder.com/100x100?text=loading`)

      // Move cursor to right side of image (easier to continue typing)
      quillRef.current.getEditor().setSelection(range.index + 1)

      // const res = await apiPostNewsImage(formData)
      const res = await FileService.create(input.files[0], { type: 'image' })

      // Remove placeholder image
      quillRef.current.getEditor().deleteText(range.index, 1)

      // Insert uploaded image
      quillRef.current
        .getEditor()
        .insertEmbed(range.index, 'image', `${process.env.REACT_APP_BASE_URL}/${res.data}`)
    }
  }

  return (
    <ReactQuill
      theme="snow"
      ref={quillRef}
      modules={{
        toolbar: {
          container: [
            ['bold', 'italic'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'image'],
            ['clean']
          ],
          handlers: {
            image: imageHandler
          }
        }
      }}
      formats={RichEditor.formats}
      bounds={'.app'}
      defaultValue={form.getFieldValue(name)}
      onChange={(value) => {
        let formValue = form.getFieldsValue()
        // remove all empty string
        value = value.replace(/<p><br><\/p>/g, '')
        form.setFieldsValue({ ...formValue, [name]: value })
      }}
    />
  )
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RichEditor.modules = {
  toolbar: [
    ['bold', 'italic'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false
  }
}
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
RichEditor.formats = ['bold', 'italic', 'blockquote', 'link', 'list', 'bullet', 'image']

/*
 * PropType validation
 */
RichEditor.propTypes = {
  placeholder: PropTypes.string
}

export default RichEditor
