import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Badge, Modal, message } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import { DateTime } from 'luxon'

import tableStore from '../../lib/store/version_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import VersionService from '../../network/services/version'

const VersionTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(VersionService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = VersionService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Version',
      dataIndex: 'build_version',
      key: 'build_version'
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform'
    },
    {
      title: 'Date',
      dataIndex: 'published_at',
      key: 'published_at',
      render: (record) => {
        if (record != null) {
          return DateTime.fromISO(record).toFormat('yyyy-MM-dd')
        }

        return ''
      }
    }
  ]

  const filterColumns = [
    { key: 'title', name: 'Title' },
    { key: 'id', name: 'ID' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = async (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await VersionService.remove(id)
        if (data?.success === true) {
          message.success(`Delete version success`)
        } else {
          message.error(`Delete version ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} versions`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More activities <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            initial={state.filters}
            setCurrentFilters={setCurrentFilters}
          />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default VersionTable
