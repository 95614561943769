import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, PageHeader, Button, Empty, Spin } from 'antd'
import useSWR from 'swr'
import { useSnapshot } from 'valtio'

import tableStore from '../../lib/store/story_table'
import StoryService from '../../network/services/story'
import { serialize } from '../../network/request'
import StoryTable from './StoryTable'

const StoryPage = () => {
  const navigate = useNavigate()
  const primaryAction = (
    <Button
      key="add"
      type="primary"
      onClick={() => {
        navigate('new')
      }}
    >
      Create Story
    </Button>
  )

  return (
    <PageHeader title="All Stories" extra={[primaryAction]}>
      <Card>
        <StoryTableWrapper />
      </Card>
    </PageHeader>
  )
}

const StoryTableWrapper = () => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(StoryService.getAll, { page: 1, limit: state.pageSize, ...state.filters })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = StoryService.toPaginate(response)

  return (
    <>
      <StoryTable total={total} />
      {/* preload next page */}
      {state.currentPage * state.pageSize < total && (
        <div style={{ display: 'none' }}>
          <StoryTable total={total} overridePage={state.currentPage + 1} />
        </div>
      )}
    </>
  )
}

export default StoryPage
