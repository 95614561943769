import React, { useEffect, useState } from 'react'
import { Input, Form, Row, Col, DatePicker, Spin, Button, Select, message } from 'antd'
import _ from 'lodash'
import moment from 'moment'

const { RangePicker } = DatePicker
const { Option } = Select

const TableFilter = ({
  dropdowns = [
    // {
    //   key: 'status',
    //   value: [
    //     { key: -1, name: 'All Status' },
    //     { key: 0, name: 'Pending' },
    //     { key: 1, name: 'Success' },
    //     { key: 2, name: 'Failed' },
    //     { key: 3, name: 'Rejected' }
    //   ]
    // }
  ],
  columns,
  initial,
  setCurrentFilters = null,
  hasDate = false
}) => {
  const [form] = Form.useForm()
  const [key, setKey] = useState(null)

  const submit = (v) => {
    let filtering = {}
    if (key != null) {
      filtering[key] = v.search
    }

    // if (key == null) {
    //   message.error('Please select search scope to proceed for searching')
    //   return
    // }
    for (let dropdown of dropdowns) {
      // && v[dropdown.key] != -1
      if (v[dropdown.key] != null) {
        filtering[dropdown.key] = v[dropdown.key]
      }
    }

    if (v.date?.length === 2) {
      filtering['from_date'] = v.date[0].toISOString()
      filtering['to_date'] = v.date[1].toISOString()
    }

    console.log(filtering)
    if (filtering !== {} && setCurrentFilters != null) {
      setCurrentFilters(filtering)
      // tableStore.state.filters = filtering
      // tableStore.state.currentPage = 1
    }
  }

  //   const reset = (v) => {
  //     let filtering = {}
  //     for (let dropdown of dropdowns) {
  //       // && v[dropdown.key] != -1
  //       if (v[dropdown.key] != null) {
  //         filtering[dropdown.key] = v[dropdown.key]
  //       }
  //     }

  //     if (v.date != null) {
  //       filtering['from_date'] = v.date[0].toISOString()
  //       filtering['to_date'] = v.date[1].toISOString()
  //     }

  //     console.log(filtering)
  //     if (filtering !== {} && setCurrentFilters != null) {
  //       setCurrentFilters(filtering)
  //       // tableStore.state.filters = filtering
  //       // tableStore.state.currentPage = 1
  //     }
  //   }

  // const [initial, setInitial] = useState(null)
  // useEffect(() => {
  //   let initial = {
  //     search: ''
  //   }

  //   for (let dropdown of dropdowns) {
  //     if (dropdown.default != null) {
  //       initial[dropdown.key] = dropdown.default
  //     }
  //   }

  //   columns.forEach((item) => {
  //     if (item.default === true) {
  //       initial.search = item.default_value
  //       setKey(item.key)
  //     }
  //   })

  //   setInitial(initial)
  //   // submit(initial)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // if (initial == null) {
  //   return <Spin />
  // }

  return (
    <Form
      form={form}
      onFinish={submit}
      initialValues={{
        ...initial,
        date:
          initial?.from_date && initial?.to_date
            ? [moment(initial.from_date), moment(initial.to_date)]
            : []
      }}
    >
      <Row gutter={[12, 12]} align="end">
        {dropdowns?.length > 0 &&
          dropdowns.map((dropdown, index) => {
            return (
              dropdown?.value?.length > 0 && (
                <Col key={index}>
                  <Form.Item name={dropdown.key}>
                    <Select
                      style={{ width: dropdown?.width ?? 100 }}
                      placeholder={dropdown.label}
                      onSelect={() => {
                        form.submit()
                      }}
                    >
                      {dropdown.value.map((d) => {
                        return (
                          <Option value={d.key} key={d.key}>
                            {d.name}
                          </Option>
                        )
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )
            )
          })}

        {hasDate && (
          <Col>
            <Form.Item name="date">
              <RangePicker />
            </Form.Item>
          </Col>
        )}

        {columns?.length > 0 && (
          <Col>
            <Form.Item name="search">
              <Input
                addonBefore={
                  <Select
                    style={{ width: 140 }}
                    onChange={(v) => setKey(v)}
                    placeholder="Search scope"
                    defaultValue={_.find(columns, { default: true })?.key}
                  >
                    {columns.map((column) => {
                      return (
                        <Option value={column.key} key={column.key}>
                          {column.name}
                        </Option>
                      )
                    })}
                  </Select>
                }
                allowClear
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default TableFilter
