import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Input,
  Table,
  Row,
  Select,
  message,
  InputNumber,
  Radio,
  Space,
  Typography,
  Button
} from 'antd'
import { isEmpty, some } from 'lodash'
import { useMediaQuery } from 'react-responsive'

import StoryService from '../../network/services/story'
import ChapterService from '../../network/services/chapter'
import FileService from '../../network/services/file'

import OptionModal from './OptionModal'
import Gaps from '../../components/Gaps'
import VideoSearch from '../../components/VideoSearch'
import CategorySearch from '../../components/CategorySearch'
import ThumbnailUpload from '../../components/ThumbnailUpload'

const StoryForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)
  const [chapterRows, setChapterRows] = useState(isEmpty(initialValue) ? [] : initialValue.chapters)
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)'
  })

  const { Option } = Select

  const handleChapterDefault = async (id) => {
    if (initialValue?.id) {
      try {
        const result = await StoryService.update(initialValue.id, {
          default_chapter_id: id
        })
        console.log(result)
        message.success('Update successful')
        refresh()
      } catch (error) {
        message.error('Update story error ' + error.message)
      }
    }
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const { data: upload } = await FileService.create(currentImage, { type: 'image' })
      values['thumbnail_url'] = upload.data
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await StoryService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update story error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await StoryService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/stories/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create story error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (text, record) => (
        <>
          <Link to={`/dashboard/chapters/${record.id}`} target="_blank">
            {text}
          </Link>
        </>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    // {
    //   title: 'Option 1',
    //   dataIndex: ['optionOneChapter', 'title'],
    //   key: ['optionOneChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 1 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_one}
    //         optionName="option_one"
    //         optionLabel="Option one answer"
    //         chapterName="option_one_chapter_id"
    //         chapterLabel="Option one chapter"
    //         thresholdName="option_one_threshold"
    //         thresholdLabel="Option one threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    // {
    //   title: 'Option 2',
    //   dataIndex: ['optionTwoChapter', 'title'],
    //   key: ['optionTwoChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 2 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_two}
    //         optionName="option_two"
    //         optionLabel="Option two answer"
    //         chapterName="option_two_chapter_id"
    //         chapterLabel="Option two chapter"
    //         thresholdName="option_two_threshold"
    //         thresholdLabel="Option two threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    // {
    //   title: 'Option 3',
    //   dataIndex: ['optionThreeChapter', 'title'],
    //   key: ['optionThreeChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 2 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_three}
    //         optionName="option_three"
    //         optionLabel="Option three answer"
    //         chapterName="option_three_chapter_id"
    //         chapterLabel="Option three chapter"
    //         thresholdName="option_three_threshold"
    //         thresholdLabel="Option three threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    // {
    //   title: 'Option 4',
    //   dataIndex: ['optionFourChapter', 'title'],
    //   key: ['optionFourChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 2 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_four}
    //         optionName="option_four"
    //         optionLabel="Option four answer"
    //         chapterName="option_four_chapter_id"
    //         chapterLabel="Option four chapter"
    //         thresholdName="option_four_threshold"
    //         thresholdLabel="Option four threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    // {
    //   title: 'Option 5',
    //   dataIndex: ['optionFiveChapter', 'title'],
    //   key: ['optionFiveChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 2 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_five}
    //         optionName="option_five"
    //         optionLabel="Option five answer"
    //         chapterName="option_five_chapter_id"
    //         chapterLabel="Option five chapter"
    //         thresholdName="option_five_threshold"
    //         thresholdLabel="Option five threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    // {
    //   title: 'Option 6',
    //   dataIndex: ['optionSixChapter', 'title'],
    //   key: ['optionSixChapter', 'title'],
    //   responsive: ['lg'],
    //   render: (chapterTitle, row) => {
    //     return (
    //       <OptionModal
    //         buttonTitle={chapterTitle}
    //         title="Option 6 Information"
    //         initialValues={{ ...row }}
    //         option={row.option_six}
    //         optionName="option_six"
    //         optionLabel="Option six answer"
    //         chapterName="option_six_chapter_id"
    //         chapterLabel="Option six chapter"
    //         thresholdName="option_six_threshold"
    //         thresholdLabel="Option six threshold"
    //         refresh={refresh}
    //       />
    //     )
    //   }
    // },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'options',
      render: (options, row) => {
        return (
          <Space size="middle" direction={isDesktopOrLaptop ? 'horizontal' : 'vertical'}>
            <Space direction="vertical">
              <Typography.Text>Option 1</Typography.Text>
              <OptionModal
                buttonTitle={row.optionOneChapter?.title}
                title="Option 1 Information"
                initialValues={{ ...row }}
                option={row.option_one}
                optionName="option_one"
                optionLabel="Option one answer"
                chapterName="option_one_chapter_id"
                chapterLabel="Option one chapter"
                thresholdName="option_one_threshold"
                thresholdLabel="Option one threshold"
                refresh={refresh}
              />
            </Space>

            <Space direction="vertical">
              <Typography.Text>Option 2</Typography.Text>
              <OptionModal
                buttonTitle={row.optionTwoChapter?.title}
                title="Option 2 Information"
                initialValues={{ ...row }}
                option={row.option_two}
                optionName="option_two"
                optionLabel="Option two answer"
                chapterName="option_two_chapter_id"
                chapterLabel="Option two chapter"
                thresholdName="option_two_threshold"
                thresholdLabel="Option two threshold"
                refresh={refresh}
              />
            </Space>

            <Space direction="vertical">
              <Typography.Text>Option 3</Typography.Text>
              <OptionModal
                buttonTitle={row.optionThreeChapter?.title}
                title="Option 3 Information"
                initialValues={{ ...row }}
                option={row.option_three}
                optionName="option_three"
                optionLabel="Option three answer"
                chapterName="option_three_chapter_id"
                chapterLabel="Option three chapter"
                thresholdName="option_three_threshold"
                thresholdLabel="Option three threshold"
                refresh={refresh}
              />
            </Space>

            <Space direction="vertical">
              <Typography.Text>Option 4</Typography.Text>
              <OptionModal
                buttonTitle={row.optionFourChapter?.title}
                title="Option 4 Information"
                initialValues={{ ...row }}
                option={row.option_four}
                optionName="option_four"
                optionLabel="Option four answer"
                chapterName="option_four_chapter_id"
                chapterLabel="Option four chapter"
                thresholdName="option_four_threshold"
                thresholdLabel="Option four threshold"
                refresh={refresh}
              />
            </Space>

            <Space direction="vertical">
              <Typography.Text>Option 5</Typography.Text>
              <OptionModal
                buttonTitle={row.optionFiveChapter?.title}
                title="Option 5 Information"
                initialValues={{ ...row }}
                option={row.option_five}
                optionName="option_five"
                optionLabel="Option five answer"
                chapterName="option_five_chapter_id"
                chapterLabel="Option five chapter"
                thresholdName="option_five_threshold"
                thresholdLabel="Option five threshold"
                refresh={refresh}
              />
            </Space>

            <Space direction="vertical">
              <Typography.Text>Option 6</Typography.Text>
              <OptionModal
                buttonTitle={row.optionSixChapter?.title}
                title="Option 6 Information"
                initialValues={{ ...row }}
                option={row.option_six}
                optionName="option_six"
                optionLabel="Option six answer"
                chapterName="option_six_chapter_id"
                chapterLabel="Option six chapter"
                thresholdName="option_six_threshold"
                thresholdLabel="Option six threshold"
                refresh={refresh}
              />
            </Space>
          </Space>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, row) => {
        if (row.id === initialValue.default_chapter_id) {
          return 'First Chapter'
        }

        return (
          <Space size="middle" direction={isDesktopOrLaptop ? 'horizontal' : 'vertical'}>
            <Button
              type="link"
              onClick={() => {
                handleChapterDefault(row.id)
              }}
            >
              Set as first chapter
            </Button>
            <Button
              type="link"
              onClick={async () => {
                const { data } = await ChapterService.remove(row.id)
                if (data?.success === true) {
                  message.success(`Delete chapter success`)
                } else {
                  message.error(`Delete chapter failed`)
                }
              }}
            >
              Delete
            </Button>
          </Space>
        )
      }
    }
  ]

  useEffect(() => {
    if (initialValue) {
      form.resetFields()
      setChapterRows(initialValue.chapters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-story"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          category_id: initialValue?.categories?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert story title' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="level"
                label="Level"
                rules={[{ required: true, message: 'Please insert story level' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item name="language" label="Language" rules={[{ required: true }]}>
                <Select placeholder="Select a language">
                  <Option value="english">English</Option>
                  <Option value="chinese">Chinese</Option>
                </Select>
              </Form.Item>

              <Form.Item name="break_type" label="Break Activity">
                <Radio.Group>
                  <Radio.Button value={true}>Yes</Radio.Button>
                  <Radio.Button value={false}>No</Radio.Button>
                </Radio.Group>
              </Form.Item>

              {id !== 'new' && (
                <Form.Item
                  name="handle"
                  label="Handle"
                  rules={[{ required: true, message: 'Please insert handle' }]}
                >
                  <Input />
                </Form.Item>
              )}
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  {
                    required: true,
                    message: 'Please insert story description'
                  }
                ]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Card>
          </Col>
          {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Card title="Chapters" bordered={false}>
              <>
                <Upload
                  accept="video/mp4"
                  beforeUpload={(file) => {
                    handleUpload(file)
                    return false
                  }}
                  listType="picture-card"
                  fileList={currentImage != null ? [currentImage] : null}
                  onPreview={handlePreview}
                  onRemove={handleRemove}
                >
                  {currentImage != null ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  // onCancel={handleCancel}
                >
                  <img alt="avatar" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </>
            </Card>
            <Gaps />
          </Col> */}
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Card bordered={false}>
              <CategorySearch
                label="Categories"
                name="category_id"
                searchKey="title"
                rules={[
                  {
                    validator(_, value) {
                      if (!isEmpty(value)) {
                        let hasEmptyString = some(value, function (item) {
                          return item === ''
                        })

                        if (hasEmptyString) {
                          return Promise.reject('Please select a category!')
                        }

                        return Promise.resolve()
                      }

                      return Promise.resolve()
                    }
                  }
                ]}
              />
              <Form.Item name="tags" label="Tags">
                <Input />
              </Form.Item>
            </Card>

            <Gaps />

            <ThumbnailUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col>
        </Row>

        <Gaps />

        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Chapters" bordered={false}>
              <Form.Item noStyle shouldUpdate={true}>
                {(form) => {
                  const breakType = form.getFieldValue('break_type')

                  if (breakType === true && (chapterRows?.length ?? 0) <= 0) {
                    return (
                      <VideoSearch
                        label="Video to be used as chapter"
                        name="video_id"
                        searchKey="title"
                        rules={[{ required: true, message: 'Please select a video!' }]}
                      />
                    )
                  }

                  if (breakType === false && (chapterRows?.length ?? 0) <= 0) {
                    return <Typography.Text>No chapter yet</Typography.Text>
                  }

                  if (breakType === undefined) {
                    return <Typography.Text>No chapter yet</Typography.Text>
                  }

                  return null
                }}
              </Form.Item>

              {chapterRows?.length > 0 && (
                <Table columns={columns} dataSource={chapterRows} pagination={false} />
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default StoryForm
