import React from 'react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Empty, Spin, PageHeader } from 'antd'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import FeedbackService from '../../network/services/feedback'
import FeedbackForm from './FeedbackForm'

const FeedbackDetail = () => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  // use this to preload
  const {
    data: response,
    error,
    mutate
  } = useSWR(serialize(id !== 'new' ? FeedbackService.get(id) : null))

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return (
      <PageHeader>
        <Spin />
      </PageHeader>
    )
  }

  const record = response?.data

  const refresh = () => {
    if (id != null) {
      if (id === 'new') {
      } else {
        mutate()
      }
    }
  }

  return (
    <PageHeader title={'View Feedback'} onBack={() => navigate('/dashboard/feedbacks')}>
      <FeedbackForm
        form={form}
        initialValue={record}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        refresh={refresh}
      />
    </PageHeader>
  )
}

export default FeedbackDetail
