import client from '../request'

const getAll = `/slides`

const get = (id) => {
  return `/slides/${id}`
}

const create = (data) => {
  return client.post('/slides', data)
}

const update = (id, data) => {
  return client.put(`/slides/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data?.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/slides/${id}`)
}

const Slideservice = {
  getAll,
  get,
  create,
  update,
  toRow,
  remove,
  toPaginate
}

export default Slideservice
