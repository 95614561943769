import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, message, Row, Checkbox, Empty, Spin } from 'antd'
import useSWR from 'swr'
import _ from 'lodash'

import FileService from '../../network/services/file'
import PushNotificationService from '../../network/services/notification'
import Gaps from '../../components/Gaps'
import FileUpload from '../../components/FileUpload'
import PushNotificationUserTable from './PushNotificationUserTable'
import { useSnapshot } from 'valtio'
import UserService from '../../network/services/user'
import { serialize } from '../../network/request'
import tableStore from '../../lib/store/notification_user_table'
import TextArea from 'antd/lib/input/TextArea'

const PushNotificationForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [all, setAll] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const toUpload = []

      values.files.forEach((file) => {
        if (file.originFileObj) {
          toUpload.push(file.originFileObj)
        }
      })

      const uploadedFiles = await Promise.all(
        toUpload.map(async (file) => {
          return await FileService.create(file, { type: 'image' })
        })
      )

      // send a list of id for existing file and src for new file
      values['image_url'] = _.first(uploadedFiles)?.data?.data
    }

    const { files, ...cleanVal } = values

    try {
      const toSent = { ...cleanVal }
      if (!all) {
        toSent['user_ids'] = selectedUsers
      }
      const { data: result } = await PushNotificationService.create(toSent)
      if (result.success) {
        message.success('Create successful')
        navigate(`/dashboard/notifications/${result.data.id}`)
      }
    } catch (error) {
      message.error('Create push notification error ' + error.message)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-push-notification"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item label="Title" name="title">
                <Input placeholder="Enter Title" readOnly={id !== 'new'} />
              </Form.Item>

              <Form.Item label="Content" name="body">
                <TextArea
                  placeholder="Enter Content"
                  autoSize={{ minRows: 6, maxRows: 10 }}
                  readOnly={id !== 'new'}
                />
              </Form.Item>

              {id === 'new' && (
                <Form.Item label="User Selection">
                  <Checkbox
                    onChange={(e) => {
                      setAll(e.target.checked)
                    }}
                    checked={all}
                  >
                    Send to all user
                  </Checkbox>
                </Form.Item>
              )}
            </Card>

            <Gaps />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <FileUpload isLoading={isLoading} setImageChange={setImageChange} maxCount={1} />
          </Col>
        </Row>
      </Form>

      {!all && id === 'new' && (
        <Card bordered={false}>
          <PushNotificationUserTableWrapper setSelectedUsers={setSelectedUsers} />
        </Card>
      )}
    </>
  )
}

const PushNotificationUserTableWrapper = ({ setSelectedUsers }) => {
  const { state } = useSnapshot(tableStore)

  // use this to preload
  const { data: response, error } = useSWR(
    serialize(UserService.getAll, { page: 1, limit: state.pageSize, ...state.filters })
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response) {
    return <Spin />
  }

  const { total } = UserService.toPaginate(response)

  return <PushNotificationUserTable total={total} setSelectedUsers={setSelectedUsers} />
}

export default PushNotificationForm
