import React, { useEffect, useMemo, useState } from 'react'
import { Input, Card, Col, Form, Row, Empty, Typography, Button } from 'antd'

import Gaps from '../../components/Gaps'
import FeedbackService from '../../network/services/feedback'

const FeedbackForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-feedback"
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item name={['user', 'email']} label="User">
                <Input readOnly />
              </Form.Item>

              <Gaps />

              <Form.Item name="message" label="Message">
                <Input.TextArea readOnly rows={5} />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>

      <HistoryList histories={initialValue.histories} />

      <ReplyForm feedbackId={initialValue.id} fromUser={initialValue.user_id} refresh={refresh} />
    </>
  )
}

const HistoryList = ({ histories }) => {
  let date = ''

  if (histories?.length === 0) {
    return <Empty description="No record" />
  }

  return (
    <Card style={{ overflowY: 'scroll', height: '40vh' }}>
      {histories?.map((item) => {
        const today = new Date()
        const dateNow = new Date(item.created_at)
        const dateNowStr = dateNow.toLocaleDateString()
        const sameDate = dateNowStr === date
        date = dateNowStr
        return (
          <>
            {!sameDate && (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 4
                }}
              >
                {dateNow.getFullYear() === today.getFullYear() &&
                dateNow.getMonth() === today.getMonth() &&
                dateNow.getDay() === today.getDay()
                  ? 'Today'
                  : dateNowStr}
              </div>
            )}
            <DialogCell history={item} />
          </>
        )
      })}
    </Card>
  )
}

const DialogCell = ({ history }) => {
  const createdAt = useMemo(() => {
    const dateTime = new Date(history.created_at)
    return dateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  }, [history.created_at])

  const breakLineHistory = useMemo(() => {
    if (!history) return []
    const arr = history.content.split(/(?=[\n])|(?<=[\n])/g)
    var brArr = []
    for (var i = 0; i < arr.length; i++) {
      const item = arr[i]
      if (item === '\n') {
        brArr.push(<br key={i} />)
        continue
      }
      brArr.push(item)
    }
    return brArr
  }, [history])

  return (
    <>
      <div
        key={history.id}
        style={{
          display: 'flex',
          margin: '6px 0',
          width: '100%',
          alignItems: 'center',
          justifyContent: history?.from_user_id === 1 ? 'flex-end' : 'flex-start'
        }}
      >
        <div style={{ maxWidth: '70%' }}>
          <div
            style={{
              padding: '6px 12px',
              background: history?.from_user_id === 1 ? 'blue' : '#eaeaea',
              maxWidth: '100%',
              borderRadius:
                history?.from_user_id === 1 ? '20px 0px 20px 20px' : '0px 20px 20px 20px'
            }}
          >
            <Typography.Text
              style={{
                color: history?.from_user_id === 1 ? 'white' : 'black',
                wordWrap: 'break-word'
              }}
            >
              {breakLineHistory?.map((item) => item)}
            </Typography.Text>
          </div>
          <div
            style={{
              color: 'grey',
              fontSize: '12px',
              paddingLeft: !history?.from_user_id === 1 ? 5 : 'auto',
              paddingRight: history?.from_user_id === 1 ? 5 : 'auto',
              textAlign: history?.from_user_id === 1 ? 'right' : 'left',
              width: '100%'
            }}
          >
            {createdAt}
          </div>
        </div>
      </div>
    </>
  )
}

const ReplyForm = ({ feedbackId, fromUser, refresh }) => {
  const [text, setText] = useState('')
  const reply = async () => {
    await FeedbackService.createHistory(feedbackId, {
      content: text,
      to_user_id: fromUser
    })

    setText('')
    refresh()
  }

  return (
    <Row gutter={[16, 16]}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
        <Card bordered={false}>
          <Form.Item label="Reply">
            <Input.TextArea
              rows={5}
              value={text}
              onChange={(e) => {
                setText(e.currentTarget.value)
              }}
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                reply()
              }}
            >
              Submit
            </Button>
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default FeedbackForm
