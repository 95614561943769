import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Row, message, Button } from 'antd'

import SubscriptionService from '../../network/services/subscription'

import Gaps from '../../components/Gaps'
import ActivitySearch from '../../components/ActivitySearch'
import PlanSearch from '../../components/PlanSearch'
import UserSearch from '../../components/UserSearch'
import { ReorderActivity, reorderActivityState } from '../../components/ActivityWrapper'

const SubscriptionForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [addLoading, setAddLoading] = useState(false)
  const [reorderLoading, setReorderLoading] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await SubscriptionService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update subscription error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await SubscriptionService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/subscriptions/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create subscription error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const addActivity = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddLoading(true)

        try {
          const activity = form.getFieldValue('add_activity')
          const { data: result } = await SubscriptionService.addActivity(id, {
            activity_id: activity
          })

          if (result.success) {
            message.success('Activity added')
            reorderActivityState.mutating = true
            form.setFieldsValue({ ...form.getFieldsValue(), add_activity: null })
          }

          setAddLoading(false)
        } catch (error) {
          console.log(error)
          setAddLoading(false)
        }
      }}
      loading={addLoading}
    >
      Add
    </Button>
  )

  const reorderActivity = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setReorderLoading(true)
        try {
          const activities = reorderActivityState.items
          const itemIds = activities?.map((e) => e.id)

          const { data: result } = await SubscriptionService.reorderActivity(id, {
            activities: itemIds
          })

          if (result.success) {
            message.success('Activity reordered')
            reorderActivityState.mutating = true
          }

          setReorderLoading(false)
        } catch (error) {
          console.log(error)
          setReorderLoading(false)
        }
      }}
      loading={reorderLoading}
    >
      Reorder
    </Button>
  )

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])


  return (
    <>
      <Form
        form={form}
        name="new-subscription"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          activities: initialValue?.activities?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <PlanSearch
                label="Plan"
                name="plan_id"
                searchKey="name"
                rules={[{ required: true, message: 'Please select a plan!' }]}
              />
              <Gaps />
              <UserSearch
                label="User"
                name="user_id"
                searchKey="email"
                rules={[{ required: true, message: 'Please select a user!' }]}
              />
            </Card>
          </Col>

          {id !== 'new' && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Card bordered={false} title="Add Activity" extra={addActivity}>
                  <ActivitySearch searchKey="name" name="add_activity" />
                </Card>

                <Gaps />

                <Card bordered={false} title="Reorder Activities" extra={reorderActivity}>
                  <ReorderActivity keyId="subscription_activity_id" />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  )
}

export default SubscriptionForm
