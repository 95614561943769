import React, { useMemo, useState } from 'react'
import { Select, Form } from 'antd'
import useSWR from 'swr'
import { useDebounce } from 'react-use'

import { serialize } from '../network/request'
import CategoryService from '../network/services/category'

const Option = Select.Option

const CategorySearch = ({ defaultValue, searchKey, ...rest }) => {
  const [val, setVal] = useState(null)
  const [debouncedSearch, setDebouncedSearch] = useState(null)

  const [,] = useDebounce(
    () => {
      let filtering = {}
      if (searchKey && val) {
        filtering[searchKey] = val
      }
      // TODO: remove default value on second search
      if (defaultValue) {
        filtering['name'] = defaultValue
      }

      setDebouncedSearch(filtering)
    },
    300,
    [val]
  )
  const { data: response, error } = useSWR(() =>
    debouncedSearch ? serialize(CategoryService.getAll, debouncedSearch) : null
  )

  const data = CategoryService.toRow(response)

  // const onNameChange = (event) => {
  //   setNewName(event.target.value)
  // }

  // const addItem = () => {
  //   // console.log('addItem')
  //   if (newName === null || newName === '') return
  //   const newData = [...data]
  //   newData.push({
  //     id: newName,
  //     title: newName
  //   })
  //   // console.log(newData)
  //   setData(newData)
  //   setNewName('')
  // }

  // if (!response) {
  //   return (
  //     <div style={{ padding: 10 }}>
  //       <Spin />
  //     </div>
  //   )
  // }

  if (error) {
    return <></>
  }

  return <CategorySearchForm {...rest} data={data} setVal={setVal} />
}

const CategorySearchForm = ({
  mode = null,
  optional = false,
  data,
  ignore,
  label,
  name,
  rules,
  setVal
}) => {
  const options = useMemo(() => {
    return data?.length > 0
      ? [
          optional && (
            <Option key="empty" value={''}>
              No Category
            </Option>
          ),
          ...data.map((d) => {
            // if (d.id === ignore) {
            //   return <></>
            // }

            return (
              <Option key={d.id} value={d.id}>
                {`${d.title}`}
              </Option>
            )
          })
        ]
      : []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <Form.Item label={label} name={name} rules={rules}>
      <Select
        mode={mode}
        showSearch
        placeholder={'Search for category'}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={(v) => {
          setVal(v)
        }}
        notFoundContent={null}
        // dropdownRender={(menu) => (
        //   <div>
        //     {menu}
        //     <Divider style={{ margin: '4px 0' }} />
        //     <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 4 }}>
        //       <Input style={{ flex: 'auto' }} value={newName} onChange={onNameChange} />
        //       <a
        //         style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
        //         onClick={addItem}
        //       >
        //         <PlusOutlined /> Add type
        //       </a>
        //     </div>
        //   </div>
        // )}
      >
        {options}
      </Select>
    </Form.Item>
  )
}

export default CategorySearch
