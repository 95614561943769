import React, { useEffect, useState } from 'react'
import { Table, Empty, Row, Col } from 'antd'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import { serialize } from '../../network/request'
import TableFilter from '../../components/TableFilter'
import UserService from '../../network/services/user'
import tableStore from '../../lib/store/notification_user_table'

const PushNotificationUserTable = ({ total, setSelectedUsers, overridePage }) => {
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)

  const { data: response } = useSWR(
    serialize(UserService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = UserService.toRowWithoutChildren(response)

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: 'Registered At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true
    }
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  return (
    <>
      <Row>
        <Col span={24}>
          <Row align="end">
            <TableFilter
              dropdowns={[]}
              columns={filterColumns}
              hasDate={false}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          rowSelection={{
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`)
              setSelectedUsers(selectedRowKeys)
            }
          }}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default PushNotificationUserTable
