import React, { useEffect, useMemo, useRef } from 'react'
import { Canvas } from '@react-three/fiber'
import { useAspect } from '@react-three/drei'
import { Controls } from './Controls'

const Scene = ({ videoRef }) => {
  const size = useAspect(1280, 720)

  const video = useMemo(() => {
    if (videoRef.current != null) {
      return videoRef.current.getInternalPlayer()
    }

    return null
  }, [videoRef])

  return (
    <mesh scale={size}>
      <mesh>
        <planeBufferGeometry args={[1, 1]} />
        <meshBasicMaterial>
          <videoTexture attach="map" args={[video]} />
        </meshBasicMaterial>
      </mesh>
    </mesh>
  )
}

const VideoCanvas = ({ videoRef, subtitles }) => {
  const canvasRef = useRef(null)

  useEffect(() => {}, [videoRef])

  return (
    <Canvas
      id="canvas"
      ref={canvasRef}
      colorManagement
      linear
      flat
      pixelRatio={[1, 2]}
      camera={{ position: [0, 0, 200] }}
    >
      <Scene videoRef={videoRef} />
      <Controls videoRef={videoRef} subtitles={subtitles} />
    </Canvas>
  )
}

export default VideoCanvas
