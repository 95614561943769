import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Row, message, Input, InputNumber, Switch } from 'antd'

import ScannerService from '../../network/services/scanner'
import ActivitySearch from '../../components/ActivitySearch'
import UserGroupSearch from '../../components/UserGroupSearch'

const ScannerForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await ScannerService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update scanner error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await ScannerService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/scanners/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create scanner error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  const isUnique = Form.useWatch('is_unique', form)

  return (
    <>
      <Form
        form={form}
        name="new-scanner"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          is_unique: initialValue?.is_unique || false,
          user_group_id: initialValue?.user_group?.id || null,
          activities: initialValue?.activities?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item name="title" label="Title">
                <Input />
              </Form.Item>

              <Form.Item name="handle" label="Code">
                <Input />
              </Form.Item>

              <Form.Item name="is_unique" label="Unique" valuePropName="checked">
                <Switch />
              </Form.Item>

              {!isUnique ? (
                <Form.Item name="subscription_activity_index" label="Position">
                  <InputNumber />
                </Form.Item>
              ) : (
                <>
                  <UserGroupSearch label="Group" name="user_group_id" searchKey="name" />

                  <ActivitySearch label="Activity" name="activity_id" searchKey="name" />
                  <p>**Note: This is required if the scanner is unique</p>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ScannerForm
