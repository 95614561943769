import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Button, Table, Tooltip, Switch } from 'antd'

import UserGroupService from '../../network/services/user_group'

import Gaps from '../../components/Gaps'
import UserSearch from '../../components/UserSearch'
import PlanSearch from '../../components/PlanSearch'
import { InfoCircleOutlined } from '@ant-design/icons'
import PackSearch from '../../components/PackSearch'

const UserGroupForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [addUserLoading, setAddUserLoading] = useState(false)
  const [addPlanLoading, setAddPlanLoading] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await UserGroupService.update(initialValue.id, values)

        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update user_group error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await UserGroupService.create(values)

        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/user-groups/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create user group error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const addPlan = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddPlanLoading(true)

        try {
          const plan = form.getFieldValue('add_plan')
          const { data: result } = await UserGroupService.addPlan(id, {
            plan_id: plan
          })

          if (result.success) {
            message.success('Plan added')
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_plan: null })
          }

          setAddPlanLoading(false)
        } catch (error) {
          console.log(error)
          setAddPlanLoading(false)
        }
      }}
      loading={addPlanLoading}
    >
      Add
    </Button>
  )

  const addUser = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddUserLoading(true)

        try {
          const user = form.getFieldValue('add_user')
          const { data: result } = await UserGroupService.addUser(id, { user_id: user })

          if (result.success) {
            message.success('User added')
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_user: null })
          }

          setAddUserLoading(false)
        } catch (error) {
          console.log(error)
          setAddUserLoading(false)
        }
      }}
      loading={addUserLoading}
    >
      Add
    </Button>
  )

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-user_group"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          is_default: initialValue == null ? false : initialValue.is_default
          // condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          // sort_order: initialValue == null ? 'ascending' : initialValue.sort_order,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert group name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="url"
                label="Code"
                rules={[{ required: true, message: 'Please insert exactly 6 characters for the code', min: 6, max: 6 }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="is_default"
                valuePropName="checked"
                label={
                  <>
                    <span style={{ marginRight: 8 }}>Default Group</span>
                    <Tooltip title="Setting this as default will disable the other default group">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <Switch />
              </Form.Item>
            </Card>

            <Gaps />

            <Card bordered={false} title="Pack" bodyStyle={{ padding: '16px 16px 0 16px' }}>
              <PackSearch searchKey="title" name="pack_id" />
            </Card>

            <Gaps />

            {id !== 'new' && (
              <>
                <Card
                  bordered={false}
                  title="Users"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addUser}
                >
                  <UserSearch
                    searchKey="email"
                    name="add_user"
                    ignores={initialValue?.users.map((user) => user.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email'
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await UserGroupService.removeUser(id, row.id)
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue?.users}
                    pagination={{
                      pageSize: 10
                    }}
                  />
                </Card>
              </>
            )}
          </Col>

          {id !== 'new' && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Card
                  bordered={false}
                  title="Plans"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addPlan}
                >
                  <PlanSearch
                    searchKey="name"
                    name="add_plan"
                    ignores={initialValue?.plans.map((plan) => plan.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name'
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await UserGroupService.removePlan(id, row.id)
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue?.plans}
                    pagination={{
                      pageSize: 5
                    }}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  )
}

export default UserGroupForm
