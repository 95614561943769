import client from '../request'

const getAll = `/playlists`

const get = (id) => {
  return `/playlists/${id}`
}

const create = (data) => {
  return client.post('/playlists', data)
}

const update = (id, data) => {
  return client.put(`/playlists/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data?.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/playlists/${id}`)
}

const PlaylistService = {
  getAll,
  get,
  create,
  update,
  toRow,
  remove,
  toPaginate
}

export default PlaylistService
