import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message } from 'antd'

import VideoService from '../../network/services/video'
import FileService from '../../network/services/file'

import Gaps from '../../components/Gaps'
import ThumbnailUpload from '../../components/ThumbnailUpload'
import VideoUpload from './VideoUpload'

import { getVideoMeta } from '../../lib/utility'

const VideoForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [currentVideo, setCurrentVideo] = useState(null)
  const [videoChange, setVideoChange] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (videoChange) {
      const { data: upload } = await FileService.create(currentVideo, { type: 'file' })
      if (upload.success === true) {
        const duration = await getVideoMeta(upload.data)
        values['duration'] = duration
      }

      values['video_url'] = upload.data
    }

    if (imageChange) {
      const { data: upload } = await FileService.create(currentImage, { type: 'image' })
      values['thumbnail_url'] = upload.data
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await VideoService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update video error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await VideoService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/videos/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create video error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-video"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert video title' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please insert video description' }]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Card>

            <Gaps />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <VideoUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentVideo={setCurrentVideo}
              setVideoChange={setVideoChange}
            />

            <Gaps />

            <ThumbnailUpload
              initialValue={initialValue}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default VideoForm
