import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FileExcelOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { CSVLink } from 'react-csv'
import useSWR from 'swr'

const CSVButton = ({ path, toRow, toCsv, filename }) => {
  const [shouldFetch, setShouldFetch] = useState(false)
  const { data: response } = useSWR(shouldFetch ? path : null)
  const rows = toRow(response)

  function handleClick() {
    setShouldFetch(true)
  }

  const csvData = useMemo(() => {
    return toCsv(rows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  return (
    <>
      <Button
        disabled={shouldFetch}
        onClick={handleClick}
        style={{ borderRadius: '30px', marginLeft: 16 }}
      >
        <FileExcelOutlined />
      </Button>
      {csvData?.length > 0 && (
        <AutoTriggerCSVButton data={csvData} filename={filename} setShouldFetch={setShouldFetch} />
      )}
    </>
  )
}

const AutoTriggerCSVButton = ({ data, filename = 'file.csv', setShouldFetch }) => {
  const ref = useRef()

  useEffect(() => {
    if (ref?.current?.link) {
      ref.current.link.click()
      setShouldFetch && setShouldFetch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])

  return <CSVLink ref={ref} data={data} filename={filename} />
}

export default CSVButton
