import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message } from 'antd'

import PlaylistService from '../../network/services/playlist'
import StorySearch from '../../components/StorySearch'

const PlaylistForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  useEffect(() => {
    form.resetFields()

    if (initialValue?.image?.src != null) {
      setCurrentImage({
        uid: 'default',
        name: '',
        status: 'done',
        url: initialValue.image.src,
        src: initialValue.image.src
      })
    } else {
      setCurrentImage(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange && currentImage != null) {
      values['image'] = currentImage
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await PlaylistService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update playlist error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await PlaylistService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/playlists/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create playlist error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  return (
    <>
      <Form
        form={form}
        name="new-playlist"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          playlist_type: initialValue == null ? 'automated' : initialValue.playlist_type,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert playlist title' }]}
              >
                <Input />
              </Form.Item>

              {id !== 'new' && (
                <Form.Item
                  name="handle"
                  label="Handle"
                  rules={[{ required: true, message: 'Please insert handle' }]}
                >
                  <Input />
                </Form.Item>
              )}
            </Card>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Card bordered={false}>
              <StorySearch
                label="Story 1"
                name="story_one_id"
                searchKey="title"
                // defaultValue={initialValue.story_one_id}
                rules={[{ required: true, message: 'Please select a story!' }]}
              />
              <StorySearch
                label="Story 2"
                name="story_two_id"
                searchKey="title"
                //defaultValue={initialValue.story_two_id}
                // rules={[{ required: true, message: 'Please select a story!' }]}
              />
              <StorySearch
                label="Story 3"
                name="story_three_id"
                searchKey="title"
                //defaultValue={initialValue.story_three_id}
                // rules={[{ required: true, message: 'Please select a story!' }]}
              />
              <StorySearch
                label="Story 4"
                name="story_four_id"
                searchKey="title"
                //defaultValue={initialValue.story_three_id}
                // rules={[{ required: true, message: 'Please select a story!' }]}
              />
              <StorySearch
                label="Story 5"
                name="story_five_id"
                searchKey="title"
                //defaultValue={initialValue.story_three_id}
                // rules={[{ required: true, message: 'Please select a story!' }]}
              />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default PlaylistForm
