import React from 'react'
import {
  GroupOutlined,
  BookOutlined,
  AudioOutlined,
  UnorderedListOutlined,
  RocketOutlined,
  DollarCircleOutlined,
  CalendarOutlined,
  ReadOutlined,
  DashOutlined,
  YoutubeOutlined,
  MessageOutlined,
  HistoryOutlined,
  CameraOutlined,
  StarOutlined,
  ContainerOutlined,
  BellOutlined,
  SlidersOutlined,
  CodeOutlined
} from '@ant-design/icons'

const options = [
  {
    key: '/dashboard/',
    label: 'Dashboard',
    icon: <BookOutlined />
  },
  {
    key: '/dashboard/versions',
    label: 'Versions',
    icon: <CodeOutlined />
  },
  {
    key: '/dashboard/notifications',
    label: 'Notifications',
    icon: <BellOutlined />
  },
  {
    key: '/dashboard/slides',
    label: 'Slides',
    icon: <SlidersOutlined />
  },
  {
    key: '/dashboard/playlists',
    label: 'Playlists',
    icon: <UnorderedListOutlined />
  },
  {
    key: '/dashboard/stories',
    label: 'Stories',
    icon: <ReadOutlined />
  },
  {
    key: '/dashboard/chapters',
    label: 'Chapters',
    icon: <BookOutlined />
  },
  {
    key: '/dashboard/videos',
    label: 'Videos',
    icon: <YoutubeOutlined />
  },
  {
    key: '/dashboard/subtitles',
    label: 'Subtitles',
    icon: <DashOutlined />
  },
  {
    key: '/dashboard/packs',
    label: 'Packs',
    icon: <ContainerOutlined />
  },
  {
    key: '/dashboard/plans',
    label: 'Plans',
    icon: <CalendarOutlined />
  },
  {
    key: '/dashboard/subscriptions',
    label: 'Subscriptions',
    icon: <DollarCircleOutlined />
  },
  {
    key: '/dashboard/activities',
    label: 'Activities',
    icon: <RocketOutlined />
  },
  {
    key: '/dashboard/user-groups',
    label: 'User Groups',
    icon: <GroupOutlined />
  },
  {
    key: '/dashboard/scanners',
    label: 'Scanner',
    icon: <CameraOutlined />
  },
  {
    key: '/dashboard/recordings',
    label: 'Recordings',
    icon: <AudioOutlined />
  },
  {
    key: '/dashboard/feedbacks',
    label: 'Feedbacks',
    icon: <MessageOutlined />
  },
  {
    key: '/dashboard/reviews',
    label: 'Reviews',
    icon: <StarOutlined />
  },
  {
    key: '/dashboard/users',
    label: 'Users',
    icon: <BookOutlined />
  },
  {
    key: '/dashboard/login_activities',
    label: 'Login Activities',
    icon: <HistoryOutlined />
  },
  {
    key: '/dashboard/settings',
    label: 'Settings',
    icon: <BookOutlined />
  }
]

export default options
