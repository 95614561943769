import React, { useEffect, useState } from 'react'
import { Button, Modal, Form, Input, InputNumber, Tooltip, message } from 'antd'
import { useNavigate } from 'react-router-dom'

import ChapterSearch from '../../components/ChapterSearch'
import ChapterService from '../../network/services/chapter'

const OptionModal = (props) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { initialValues, option, refresh } = props

  const toggleModal = () => {
    setIsModalVisible(!isModalVisible)
  }

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValues?.id) {
      try {
        await ChapterService.updateChapterOption(initialValues.id, values)
        message.success('Update successful')
        refresh()
      } catch (error) {
        message.error('Update chapter error ' + error.message)
      } finally {
      }
    } else {
      let result
      try {
        result = await ChapterService.create(values)
        message.success('Create successful')
      } catch (error) {
        message.error('Create chapter error ' + error.message)
      } finally {
        console.log(result)
        refresh()
      }
    }

    setIsLoading(false)
    toggleModal()
  }

  const handleCancel = async () => {
    toggleModal()
  }

  const handleOk = async () => {
    await form.submit()
  }

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [initialValues])

  return (
    <>
      <Tooltip title={option ?? ''}>
        <Button type={props.buttonTitle ? 'primary' : 'secondary'} onClick={toggleModal}>
          {props.buttonTitle ?? 'Set'}
        </Button>
      </Tooltip>

      <Modal
        title={props.title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={handleOk}>
            Submit
          </Button>
        ]}
      >
        <Form
          form={form}
          preserve={false}
          initialValues={initialValues}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item name={props.optionName} label={props.optionLabel}>
            <Input />
          </Form.Item>
          <ChapterSearch
            name={props.chapterName}
            label={props.chapterLabel}
            searchKey="title"
            ignore={initialValues.id}
            story={initialValues.story_id}
          />
          <Form.Item name={props.thresholdName} label={props.thresholdLabel}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default OptionModal
