import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'

import tableStore from '../../lib/store/setting_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import SettingService from '../../network/services/setting'
import CSVButton from '../../components/CSVButton'

const SettingTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(SettingService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = SettingService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value'
    },
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      key: 'enabled',
      render: (enabled) => (enabled ? 'Yes' : 'No')
    }
  ]

  const filterColumns = [
    { key: 'title', name: 'Title' },
    { key: 'id', name: 'ID' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await SettingService.remove(id)
        if (data?.success === true) {
          message.success(`Delete setting success`)
        } else {
          message.error(`Delete setting ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} settings`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={3}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={21}>
          <Row align="end">
            <TableFilter
              dropdowns={[]}
              columns={filterColumns}
              hasDate={false}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            <CSVButton
              path={serialize(SettingService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="settings.csv"
              toRow={SettingService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    email: row.user.email,
                    score: row.score,
                    content: row.content,
                    activity: row.activity.name,
                    want_more: row.more_activity ? 'Yes' : 'No'
                  }
                })
              }}
            />
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default SettingTable
