import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Button, Input, Row, message } from 'antd'

import Gaps from '../../components/Gaps'
import UserService from '../../network/services/user'

const UserForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  // const navigate = useNavigate()
  const { id } = useParams()

  const [passwordForm] = Form.useForm()

  const submitPassword = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const result = await UserService.changePassword({ password: values.password, user: id })
      if (result.data.success) {
        message.success('Password changed successfully')
        passwordForm.resetFields()
      }
    } catch (error) {
      message.error('Password change error ' + error.message)
    }

    setIsLoading(false)
  }

  // const handleSubmit = async (values) => {
  //   if (isLoading) return
  //   setIsLoading(true)

  //   if (videoChange) {
  //     const upload = await FileService.create(currentVideo)

  //     if (upload.success === true) {
  //       const duration = await getVideoMeta(upload.data)
  //       values['duration'] = duration
  //     }

  //     values['video_url'] = upload.data
  //   }

  //   if (imageChange) {
  //     const upload = await FileService.create(currentImage)
  //     values['thumbnail_url'] = upload.data
  //   }

  //   if (initialValue?.id) {
  //     try {
  //       const result = await VideoService.update(initialValue.id, values)
  //       message.success('Update successful')
  //       refresh()
  //     } catch (error) {
  //       message.error('Update video error ' + error.message)
  //     }
  //   } else {
  //     let result
  //     try {
  //       result = await VideoService.create(values)
  //       message.success('Create successful')
  //     } catch (error) {
  //       message.error('Create video error ' + error.message)
  //     } finally {
  //       if (result) {
  //         console.log(result)
  //         navigate('/dashboard/videos')
  //       }
  //     }
  //   }

  //   setIsLoading(false)
  // }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Form
            form={form}
            name="new-user"
            // onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              ...initialValue,
              address: `${initialValue?.profile?.address_1 ?? ''} ${
                initialValue?.profile?.address_2 ?? ''
              } ${initialValue?.profile?.postcode ?? ''}`,
              condition_match: initialValue == null ? 'all' : initialValue.condition_match,
              sort_order: initialValue == null ? 'ascending' : initialValue.sort_order,
              date_of_birth:
                initialValue?.children?.length > 0
                  ? new Date(initialValue?.children?.[0]?.birthdate).toLocaleString()
                  : ''
            }}
          >
            <Card bordered={false}>
              <Form.Item name="email" label="Email">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['profile', 'name']} label="Name">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['children', [0], 'name']} label="Children Name">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['profile', 'phone']} label="Phone Number">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['subscription', 'plan', 'name']} label="Plan">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={'address'} label="Address">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={'date_of_birth'} label="Children Date Of Birth">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={'scanner_code'} label="Registered by">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={'origin'} label="Starter pack">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={'origin_description'} label="Starter pack details">
                <Input readOnly />
              </Form.Item>
            </Card>
          </Form>

          <Gaps />
        </Col>

        {initialValue.id != null && (
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Form
              form={passwordForm}
              name="change-password"
              onFinish={submitPassword}
              layout="vertical"
            >
              <Card
                bordered={false}
                title="Change Password"
                extra={
                  <Button
                    onClick={() => {
                      console.log('ttt')
                      passwordForm.submit()
                    }}
                  >
                    Submit
                  </Button>
                }
              >
                <Form.Item
                  name="password"
                  label="New Password"
                  rules={[{ required: true, message: 'Please insert the new password' }]}
                >
                  <Input />
                </Form.Item>
              </Card>
            </Form>
          </Col>
        )}
      </Row>
    </>
  )
}

export default UserForm
