import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu, Tag } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import { DateTime } from 'luxon'

import tableStore from '../../lib/store/recording_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import RecordingService from '../../network/services/recording'
import CSVButton from '../../components/CSVButton'

const RecordingTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(RecordingService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = RecordingService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'User',
      dataIndex: ['user', 'email'],
      key: ['user', 'email']
    },
    {
      title: 'Playlist',
      dataIndex: ['activity', 'playlist', 'title'],
      key: ['activity', 'playlist', 'title']
    },
    {
      title: 'Activity',
      dataIndex: ['activity', 'name'],
      key: ['activity', 'name']
    },
    {
      title: 'Chapter',
      dataIndex: ['chapter', 'title'],
      key: ['chapter', 'title']
    },
    {
      title: 'Option Answer',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score'
    },
    {
      title: 'Recording Answer',
      dataIndex: 'hypothesis',
      key: 'hypothesis'
    },
    {
      title: 'Recording Answer Pinyin',
      dataIndex: 'pinyin_hypothesis',
      key: 'pinyin_hypothesis'
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language'
    },
    {
      title: 'Device',
      dataIndex: 'device',
      key: 'device'
    },
    {
      title: 'First Chapter',
      dataIndex: ['chapter', 'story', 'default_chapter_id'],
      key: ['chapter', 'story', 'default_chapter_id'],
      render: (value, row) => {
        return value === row.chapter_id ? (
          <Tag color="success">Yes</Tag>
        ) : (
          <Tag color="error">No</Tag>
        )
      }
    },
    {
      title: 'Last Recording',
      dataIndex: 'is_last_recording',
      key: 'is_last_recording',
      render: (value) => {
        return value ? <Tag color="success">Yes</Tag> : <Tag color="error">No</Tag>
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (date) => {
        return date ? DateTime.fromISO(date).toFormat('dd/MM/yyyy hh:mm:ss a') : ''
      }
    }
  ]

  const filterColumns = [
    { key: 'email', name: 'Email' },
    { key: 'playlist', name: 'Playlist' },
    { key: 'id', name: 'ID' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await RecordingService.remove(id)
        if (data?.success === true) {
          message.success(`Delete recording success`)
        } else {
          message.error(`Delete recording ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} recordings`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />
      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row align="end">
            <TableFilter
              dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            <CSVButton
              path={serialize(RecordingService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="recordings.csv"
              toRow={RecordingService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    user: row.user?.email,
                    playlist: row.activity?.playlist?.title,
                    activity: row.activity?.name,
                    chapter: row.chapter?.title,
                    option: row.category,
                    answer: row.hypothesis,
                    pinyin: row.pinyin_hypothesis,
                    score: row.hypothesis_score,
                    language: row.language,
                    calibrations: row.calibrations?.join(' | ') ?? '',
                    volumes: row.volumes?.join(' | ') ?? '',
                    has_recording: row.recording_id == null ? 'no' : 'yes',
                    is_last_recording: row.is_last_recording ? 'yes' : 'no',
                    device: row.device,
                    date:
                      row.created_at &&
                      DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy hh:mm:ss a')
                  }
                })
              }}
            />
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default RecordingTable
