import React, { useEffect, useState } from 'react'
import { Card, Button, Modal, message, Upload } from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'

import { getBase64 } from '../../lib/utility'

const VideoUpload = ({ initialValue, isLoading, setCurrentVideo, setVideoChange }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewVideo, setPreviewVideo] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState(null)

  useEffect(() => {
    if (initialValue?.video_url != null) {
      setFileList([
        {
          uid: initialValue?.video_url,
          name: `${initialValue?.title}`,
          status: 'done',
          url: initialValue?.video_url,
          src: initialValue?.video_url
        }
      ])
    }
  }, [initialValue])

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewVideo(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleRemove = (value) => {
    setCurrentVideo(null)
  }

  const handleUpload = async (file) => {
    const isLt100M = file.size / 1024 / 1024 < 100
    if (!isLt100M) {
      message.error('Video must be smaller than 100MB!')
      return false
    } else {
      setVideoChange(true)
      setCurrentVideo(file)
      message.success('Video finished uploading.')
    }

    // try {
    //   const upload = await FileService.create(file)
    //   console.log(upload)
    //   if (upload.success == true) {
    //     // setCurrentVideo({ src: upload.data, url: upload.data, uid: file.uid })
    //     setCurrentVideo(upload.data)
    //     const video = document.createElement('video')
    //     video.src = upload.data
    //     video.onloadedmetadata = () => {
    //       // Remove some unnecessary decimals with toFixed
    //       setCurrentVideoDuration(video.duration.toFixed(2))
    //       console.log(video.duration.toFixed(2))
    //       // Wait for video duration to be calculated to satisfy this condition
    //       setVideoChange(true)
    //     }
    //   }
    // } catch (error) {
    //   message.error(`Upload failed : ${error?.data?.statusText ?? error?.message ?? 'unknown'}`)
    // }
  }

  return (
    <Card title="Video" bordered={false}>
      <>
        <Upload
          accept="video/*"
          beforeUpload={(file) => {
            handleUpload(file)
            return false
          }}
          fileList={fileList}
          onPreview={handlePreview}
          onRemove={handleRemove}
          onChange={(value) => {
            console.log(value.fileList[0])
            setFileList(value.fileList)
          }}
          maxCount={1}
        >
          <Button icon={isLoading ? <LoadingOutlined /> : <UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload>

        <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
          <video src={previewVideo} style={{ width: '100%' }} autoPlay playsInline controls />
        </Modal>
      </>
    </Card>
  )
}

export default VideoUpload
