import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Switch, Tooltip, Select, Button, Modal } from 'antd'

import FileService from '../../network/services/file'
import ActivityService from '../../network/services/activity'
import Gaps from '../../components/Gaps'
import FileUpload from '../../components/FileUpload'
import PlaylistSearch from '../../components/PlaylistSearch'
// import SubscriptionSearch from '../../components/SubscriptionSearch'
import RichEditor from '../../components/RichEditor'
import { InfoCircleOutlined } from '@ant-design/icons'
import VideoSearch from '../../components/VideoSearch'
import VideoService from '../../network/services/video'

const ActivityForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [previewVideoVisible, setPreviewVideoVisible] = useState(false)
  const [previewVideo, setPreviewVideo] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const toUpload = []
      const toRemain = []

      values.files.forEach((file) => {
        if (file.originFileObj) {
          toUpload.push(file.originFileObj)
        } else {
          toRemain.push({ id: file.id })
        }
      })

      const uploadedFiles = await Promise.all(
        toUpload.map(async (file) => {
          return await FileService.create(file, { type: 'image' })
        })
      )

      // send a list of id for existing file and src for new file
      values['thumbnails'] = [
        ...toRemain,
        ...uploadedFiles.map((file) => ({
          src: file.data.data
        }))
      ]
    }

    // let subscriptions = []
    // if (values.subscription_id?.length > 0) {
    //   subscriptions = values.subscription_id.filter((e) => e)
    // }

    if (initialValue?.id) {
      try {
        const { data: result } = await ActivityService.update(initialValue.id, {
          ...values
          // subscription_id: subscriptions
        })
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update activity error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await ActivityService.create({
          ...values
          // subscription_id: subscriptions
        })
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/activities/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create activity error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-activity"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          files: initialValue?.thumbnails.map((file) => {
            return {
              id: file.id,
              uid: file.id,
              name: `${file.alt}`,
              status: 'done',
              url: file.src,
              src: file.src
            }
          }),
          subscription_id: initialValue?.subscriptions?.map((item) => item.id),
          playlist_id: initialValue?.playlist?.id
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert activity name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="short_description"
                label="Short Description"
                rules={[{ required: true, message: 'Please insert short description' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please insert activity description' }]}
              >
                <Input style={{ display: 'none' }} />
                <RichEditor form={form} name="description" />
              </Form.Item>

              <Form.Item
                name="tips"
                label="Tips"
                rules={[{ required: true, message: 'Please insert tips' }]}
              >
                <Input style={{ display: 'none' }} />
                <RichEditor form={form} name="tips" />
              </Form.Item>

              <Form.Item name="review_text" label="Review Text">
                <Input style={{ display: 'none' }} />
                <RichEditor form={form} name="review_text" />
              </Form.Item>
            </Card>

            <Gaps />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Card bordered={false}>
              {/* <SubscriptionSearch
                mode="multiple"
                label="User"
                name="subscription_id"
                searchKey="email"
              />

              <Gaps /> */}

              <PlaylistSearch optional label="Playlist" name="playlist_id" searchKey="title" />
            </Card>

            <Gaps />

            <Card bordered={false}>
              <Form.Item
                name="is_community"
                valuePropName="checked"
                label="Community Plan"
                style={{ margin: 0 }}
              >
                <Switch />
              </Form.Item>

              <Gaps />

              <Form.Item
                name="region"
                label={
                  <>
                    <span style={{ marginRight: 8 }}>Region</span>
                    <Tooltip title="To filter region for community activities">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                rules={[{ required: true, message: 'Please select the region' }]}
              >
                <Select>
                  <Select.Option value="sg">Singapore</Select.Option>
                  <Select.Option value="tw">Taiwan</Select.Option>
                </Select>
              </Form.Item>
            </Card>

            <Gaps />

            <FileUpload isLoading={isLoading} setImageChange={setImageChange} />

            <Gaps />

            <Card
              title="Preview Video"
              bordered={false}
              extra={
                <Button
                  onClick={async () => {
                    try {
                      const vidId = form.getFieldValue('video_id')
                      const { data: vid } = await VideoService.getByClient(vidId)
                      console.log(vid)
                      setPreviewVideo(vid)
                      setPreviewVideoVisible(true)
                    } catch (error) {
                      message.error('Could not find the video')
                    }
                  }}
                >
                  Preview
                </Button>
              }
            >
              <VideoSearch
                name="preview_video_id"
                searchKey="title"
                rules={[{ required: true, message: 'Please select a video!' }]}
              />

              <Modal
                visible={previewVideoVisible}
                title={previewVideo?.data?.title}
                footer={null}
                onCancel={() => {
                  setPreviewVideoVisible(false)
                }}
              >
                <video src={previewVideo?.data?.video_url} controls style={{ width: '100%' }} />
              </Modal>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ActivityForm
