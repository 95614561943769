import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Modal, message, Row, Col, Dropdown, Menu } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import { DateTime } from 'luxon'

import tableStore from '../../lib/store/user_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import UserService from '../../network/services/user'
import CSVButton from '../../components/CSVButton'

const UserTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(UserService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = UserService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      console.log(keySelected)
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: ['profile', 'name'],
      key: ['profile', 'name'],
      sorter: true
    },
    {
      title: 'Children',
      dataIndex: ['children', [0], 'name'],
      key: ['children', [0], 'name']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true
    },
    {
      title: 'Plan',
      dataIndex: ['subscription', 'plan', 'name'],
      key: ['subscription', 'plan', 'name'],
      sorter: true
    },
    {
      title: 'Child date of birth',
      dataIndex: ['children', [0], 'birthdate'],
      key: ['children', [0], 'birthdate'],
      sorter: true,
      render: (text) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Registered At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      render: (text) => {
        return text ? DateTime.fromISO(text).toFormat('dd/MM/yyyy') : ''
      }
    },
    {
      title: 'Update',
      dataIndex: 'receive_update',
      key: 'receive_update',
      render: (text) => {
        return text === 1 ? 'Yes' : 'No'
      }
    },
    {
      title: 'Registered By',
      dataIndex: 'scanner_code',
      key: 'scanner_code'
    },
    {
      title: 'Starter pack',
      dataIndex: 'origin',
      key: 'origin'
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region'
    },
  ]

  const filterColumns = [{ key: 'email', name: 'Email' }, { key: 'region', name: 'Region' }]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  const batchDeleteRows = async () => {
    try {
      for (const id of selectedRowKeys) {
        const { data } = await UserService.remove(id)
        if (data?.success === true) {
          message.success(`Delete user success`)
        } else {
          message.error(`Delete user ${id} failed`)
        }
        tableStore.state.refresh = Math.random()
      }

      setVisible(false)
    } catch (e) {
      message.error({ content: e.message, className: 'message-error' })
    }
  }

  const DeleteModal = () => {
    return (
      <Modal
        title={`Remove ${selectedRowKeys.length} users`}
        visible={visible}
        onOk={() => {
          batchDeleteRows()
        }}
        onCancel={() => {
          setVisible(false)
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        This can't be undone
      </Modal>
    )
  }

  return (
    <>
      <DeleteModal />

      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More Actions <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <Row align="end">
            <TableFilter
              dropdowns={[]}
              columns={filterColumns}
              hasDate={true}
              initial={state.filters}
              setCurrentFilters={setCurrentFilters}
            />

            <CSVButton
              path={serialize(UserService.getAll, {
                page: 1,
                limit: total,
                sort: state.sort ?? 'created_at:desc',
                ...state.filters
              })}
              filename="users.csv"
              toRow={UserService.toRow}
              toCsv={(rows) => {
                return rows.map((row) => {
                  return {
                    name: row.profile?.name,
                    email: row.email,
                    plan: row.subscription?.plan?.name,
                    children: row.children[0]?.name,
                    birthdate:
                      row.children[0]?.birthdate &&
                      DateTime.fromISO(row.children[0].birthdate).toFormat('dd/MM/yyyy'),
                    registered:
                      row.created_at && DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy'),
                    receive_update: row.receive_update,
                    registered_by: row.scanner_code,
                    starter_pack: row.origin,
                    region: row.region
                  }
                })
              }}
            />
          </Row>
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          expandable={{
            rowExpandable: (record) => false
          }}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default UserTable
