import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Button, Switch, Tooltip, Table, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import PackService from '../../network/services/pack'

import Gaps from '../../components/Gaps'
import PlanSearch from '../../components/PlanSearch'
import ThumbnailUpload from '../../components/ThumbnailUpload'
import FileService from '../../network/services/file'

const PackForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [addPlanLoading, setAddPlanLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const { data: upload } = await FileService.create(currentImage, { type: 'image' })
      values['thumbnail_url'] = upload.data
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await PackService.update(initialValue.id, values)

        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update pack error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await PackService.create(values)

        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/packs/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create pack error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const addPlan = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddPlanLoading(true)

        try {
          const plan = form.getFieldValue('add_plan')
          const { data: result } = await PackService.addPlan(id, { plan_id: plan })

          if (result.success) {
            message.success('Plan added')
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_plan: null })
          }

          setAddPlanLoading(false)
        } catch (error) {
          console.log(error)
          setAddPlanLoading(false)
        }
      }}
      loading={addPlanLoading}
    >
      Add
    </Button>
  )

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-pack"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          plans: initialValue?.plans?.map((plan) => plan.id),
          // library_activities: initialValue?.library_activities?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert pack title' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="description" label="Description">
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>

              <Form.Item
                name="region"
                label="Region"
                rules={[{ required: true, message: 'Please select the region' }]}
              >
                <Select>
                  <Select.Option value="sg">Singapore</Select.Option>
                  <Select.Option value="tw">Taiwan</Select.Option>
                </Select>
              </Form.Item>
            </Card>

            <Gaps />

            <ThumbnailUpload
              initialValue={{ thumbnail_url: initialValue?.thumbnail_url }}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              optional={false}
            />
          </Col>

          {id !== 'new' && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Card
                  bordered={false}
                  title="Plans"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addPlan}
                >
                  <PlanSearch
                    searchKey="name"
                    name="add_plan"
                    ignores={initialValue.plans.map((plan) => plan.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await PackService.removePlan(id, row.id)
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue.plans}
                    pagination={{
                      pageSize: 5
                    }}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  )
}

export default PackForm
