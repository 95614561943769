import React, { useEffect, useState } from 'react'
import { Card, Modal, message, Upload, Form } from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'
import { getBase64 } from '../lib/utility'

const FileUpload = ({ isLoading, setImageChange, optional = true, maxCount }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewImage(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleRemove = (value) => {
    setImageChange(true)
  }

  const handleUpload = async (file) => {
    // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    // if (!isJpgOrPng) {
    //   message.error('You can only upload JPG/PNG file!')
    //   return false
    // }

    // const isMp4 = file.type == 'video/mp4'
    // if (!isMp4) {
    //   message.error('You can only upload mp4 file!')
    //   return false
    // }

    const isLt100M = file.size / 1024 / 1024 < 100
    if (!isLt100M) {
      message.error('File must smaller than 100MB!')
      return false
    }

    setImageChange(true)

    // try {
    //   const upload = await FileService.create(file)
    //   console.log(upload)
    //   if (upload.success == true) {
    //     // setCurrentImage({ src: upload.data, url: upload.data, uid: file.uid })
    //     setCurrentImage(upload.data)
    //     const video = document.createElement('video')
    //     video.src = upload.data
    //     video.onloadedmetadata = () => {
    //       // Remove some unnecessary decimals with toFixed
    //       setCurrentImageDuration(video.duration.toFixed(2))
    //       console.log(video.duration.toFixed(2))
    //       // Wait for video duration to be calculated to satisfy this condition
    //       setImageChange(true)
    //     }
    //   }
    // } catch (error) {
    //   message.error(`Upload failed : ${error?.data?.statusText ?? error?.message ?? 'unknown'}`)
    // }
  }

  const normFile = (e) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Card
      title={
        optional ? (
          'Thumbnail'
        ) : (
          <>
            <span
              style={{
                display: 'inline-block',
                marginRight: 4,
                color: '#a11921',
                fontSize: 12,
                lineHeight: 1
              }}
            >
              *
            </span>
            <span>Thumbnail</span>
          </>
        )
      }
      bordered={false}
    >
      <Form.Item name="files" valuePropName="fileList" getValueFromEvent={normFile}>
        <Upload
          accept="image/*"
          listType="picture-card"
          beforeUpload={async (file) => {
            handleUpload(file)
            return false
          }}
          onPreview={handlePreview}
          onRemove={handleRemove}
          // assume file uploader is for multiple files
          maxCount={maxCount}
        >
          {isLoading ? <LoadingOutlined /> : <UploadOutlined style={{ fontSize: 20 }} />}
        </Upload>
      </Form.Item>

      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img src={previewImage} alt="preview" style={{ width: '100%' }} />
      </Modal>
    </Card>
  )
}

export default FileUpload
