import client from '../request'

const getAll = 'chapters'

const get = (id) => {
  return `/chapters/${id}`
}

const create = (data) => {
  return client.post('/chapters', data)
}

const update = (id, data) => {
  return client.put(`/chapters/${id}`, data)
}

const updateChapterOption = (id, data) => {
  return client.put(`/chapters/${id}/options`, data)
}

const remove = (id, data) => {
  return client.delete(`/chapters/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const ChapterService = {
  getAll,
  get,
  create,
  update,
  updateChapterOption,
  toRow,
  toPaginate,
  remove
}

export default ChapterService
