import React, { useEffect, useState } from 'react'
import { Table, Button, Empty, Row, Col, Dropdown, Menu, Badge } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useSnapshot } from 'valtio'
import useSWR from 'swr'
import { DateTime } from 'luxon'

import tableStore from '../../lib/store/notification_table'
import TableFilter from '../../components/TableFilter'
import { serialize } from '../../network/request'
import PushNotificationService from '../../network/services/notification'

const PushNotificationTable = ({ total, overridePage }) => {
  const navigate = useNavigate()
  const { state } = useSnapshot(tableStore)
  const [currentFilters, setCurrentFilters] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visible, setVisible] = useState(false)

  const { data: response } = useSWR(
    serialize(PushNotificationService.getAll, {
      page: overridePage ?? state.currentPage,
      limit: state.pageSize,
      sort: state.sort ?? 'created_at:desc',
      refresh: state.refresh,
      ...state.filters
    })
  )
  const rows = PushNotificationService.toRow(response)

  const rowSelection = {
    selectedRowKeys,
    onChange: (keySelected) => {
      setSelectedRowKeys(keySelected)
    }
  }

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: 'Content',
      dataIndex: 'body',
      key: 'body',
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'sent',
      key: 'sent',
      render: (record) => {
        let s, t
        switch (record) {
          case 0:
            s = 'processing'
            t = 'new'
            break
          case 1:
            s = 'success'
            t = 'sent'
            break
          default:
            s = 'processing'
            t = 'new'
            break
        }
        return <Badge status={s} text={t} />
      }
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (record) => {
        if (record != null) {
          return DateTime.fromISO(record).toFormat('yyyy-MM-DD hh:mmA')
        }

        return ''
      }
    }
  ]

  const filterColumns = [
    { key: 'title', name: 'Title' },
    { key: 'id', name: 'ID' }
  ]
  filterColumns.forEach((item) => {
    if (tableStore.state?.filters && Object.keys(tableStore.state.filters).includes(item.key)) {
      item.default_value = tableStore.state.filters[item.key]
      item.default = true
    }
  })

  useEffect(() => {
    if (currentFilters != null) {
      tableStore.state.filters = { ...currentFilters }
      tableStore.state.currentPage = 1
    }
  }, [currentFilters])

  const handleMenuClick = (e) => {
    if (e.key === 'delete') {
      setVisible(true)
    }
  }

  return (
    <>
      <Row>
        <Col span={6}>
          {selectedRowKeys?.length > 0 ? (
            <Dropdown
              overlay={
                <Menu onClick={handleMenuClick}>
                  <Menu.Item key="delete">Delete</Menu.Item>
                </Menu>
              }
              trigger="click"
            >
              <Button type="primary">
                More activities <CaretDownOutlined />
              </Button>
            </Dropdown>
          ) : (
            <></>
          )}
        </Col>
        <Col span={18}>
          <TableFilter
            dropdowns={[]}
            columns={filterColumns}
            hasDate={false}
            initial={state.filters}
            setCurrentFilters={setCurrentFilters}
          />
        </Col>
      </Row>

      {rows?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={rows}
          onChange={(pagination, filters, sorter) => {
            // console.log(pagination, filters, sorter)

            tableStore.state.sort =
              sorter != null && !_.isEmpty(sorter?.columnKey) && !_.isEmpty(sorter?.order)
                ? `${sorter.columnKey ?? 'created_at'}:${
                    sorter.order === 'ascend' ? 'asc' : 'desc'
                  }`
                : null

            tableStore.state.currentPage = pagination.current
            // tableStore.state.pageSize = pagination.pageSize
          }}
          pagination={{
            total,
            pageSize: state.pageSize,
            current: state.currentPage
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (selectedRowKeys?.length > 0) {
                  if (selectedRowKeys.includes(record.id)) {
                    const filterKeys = _.filter([...selectedRowKeys], function (o) {
                      return o !== record.id
                    })
                    setSelectedRowKeys([...filterKeys])
                  } else {
                    setSelectedRowKeys([...selectedRowKeys, record.id])
                  }
                } else {
                  navigate(`${record.id}`)
                }
              } // click row
            }
          }}
          rowSelection={rowSelection}
        />
      ) : (
        <Empty description={'No record found'} />
      )}
    </>
  )
}

export default PushNotificationTable
