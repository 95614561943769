import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Input, message, Row, DatePicker, Select } from 'antd'
import moment from 'moment'

import VersionService from '../../network/services/version'

const VersionForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { data: result } = await VersionService.create(values)
      if (result.success) {
        message.success('Create successful')
        navigate(`/dashboard/versions/${result.data.id}`)
      }
    } catch (error) {
      message.error('Create version error ' + error.message)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-version"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          published_at: initialValue && initialValue.published_at ? moment(initialValue.published_at) : null
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item label="App URL" name="app_url">
                <Input placeholder="Enter App URL" />
              </Form.Item>

              <Form.Item label="Build Number" name="build_number">
                <Input placeholder="Enter Build Number" />
              </Form.Item>

              <Form.Item label="Build Version" name="build_version">
                <Input placeholder="Enter Build Version" />
              </Form.Item>

              <Form.Item label="Force Update" name="force_update">
                <Select placeholder="Enter Force Update">
                  <Select.Option value={true}>Yes</Select.Option>
                  <Select.Option value={false}>No</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Platform" name="platform">
                <Select placeholder="Enter Platform">
                  <Select.Option value="android">Android</Select.Option>
                  <Select.Option value="ios">iOS</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Release Note" name="release_note">
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>

              <Form.Item label="Date" name="published_at">
                <DatePicker />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default VersionForm
