import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, message, Button, Switch, Tooltip, Table } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import PlanService from '../../network/services/plan'

import Gaps from '../../components/Gaps'
import UserSearch from '../../components/UserSearch'
import ActivitySearch from '../../components/ActivitySearch'
import { reorderActivityState } from '../../components/ActivityWrapper'
import ThumbnailUpload from '../../components/ThumbnailUpload'
import FileService from '../../network/services/file'

const PlanForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()

  const [addUserLoading, setAddUserLoading] = useState(false)
  const [addDefaultActivityLoading, setAddDefaultActivityLoading] = useState(false)
  const [addLibraryActivityLoading, setAddLibraryActivityLoading] = useState(false)
  const [reorderLoading, setReorderLoading] = useState(false)
  const [currentImage, setCurrentImage] = useState(null)
  const [imageChange, setImageChange] = useState(false)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (imageChange) {
      const { data: upload } = await FileService.create(currentImage, { type: 'image' })
      values['thumbnail'] = upload.data
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await PlanService.update(initialValue.id, values)

        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update plan error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await PlanService.create(values)

        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/plans/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create plan error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const addUser = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddUserLoading(true)

        try {
          const user = form.getFieldValue('add_user')
          const { data: result } = await PlanService.addUser(id, { user_id: user })

          if (result.success) {
            message.success('User added')
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_user: null })
          }

          setAddUserLoading(false)
        } catch (error) {
          console.log(error)
          setAddUserLoading(false)
        }
      }}
      loading={addUserLoading}
    >
      Add
    </Button>
  )

  const addDefaultActivity = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddDefaultActivityLoading(true)

        try {
          const activity = form.getFieldValue('add_default_activity')
          const { data: result } = await PlanService.addActivity(id, {
            type: 'default',
            activity_id: activity
          })

          if (result.success) {
            message.success('Default activity added')
            // reorderActivityState.mutating = true
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_default_activity: null })
          }

          setAddDefaultActivityLoading(false)
        } catch (error) {
          console.log(error)
          setAddDefaultActivityLoading(false)
        }
      }}
      loading={addDefaultActivityLoading}
    >
      Add
    </Button>
  )

  const addLibraryActivity = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setAddLibraryActivityLoading(true)

        try {
          const activity = form.getFieldValue('add_library_activity')
          const { data: result } = await PlanService.addActivity(id, {
            type: 'library',
            activity_id: activity
          })

          if (result.success) {
            message.success('Library activity added')
            // reorderActivityState.mutating = true
            refresh()
            form.setFieldsValue({ ...form.getFieldsValue(), add_library_activity: null })
          }

          setAddLibraryActivityLoading(false)
        } catch (error) {
          console.log(error)
          setAddLibraryActivityLoading(false)
        }
      }}
      loading={addLibraryActivityLoading}
    >
      Add
    </Button>
  )

  const reorderActivity = (
    <Button
      key={'save'}
      type="primary"
      onClick={async () => {
        setReorderLoading(true)
        try {
          const activities = reorderActivityState.items
          const itemIds = activities?.map((e) => e.id)

          const { data: result } = await PlanService.reorderActivity(id, { activities: itemIds })

          if (result.success) {
            message.success('Activity reordered')
            reorderActivityState.mutating = true
          }

          setReorderLoading(false)
        } catch (error) {
          console.log(error)
          setReorderLoading(false)
        }
      }}
      loading={reorderLoading}
    >
      Reorder
    </Button>
  )

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-plan"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          // users: initialValue?.users?.map((user) => user.id),
          // library_activities: initialValue?.library_activities?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order,
          days: initialValue == null ? 6 : initialValue.days, // hardcode value
          is_default: initialValue == null ? false : initialValue.is_default,
          is_active: initialValue == null ? true : initialValue.is_active
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
            <Card bordered={false}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Please insert plan name' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please insert plan description' }]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>

              {/* <Form.Item
                name="days"
                label="Plan days"
                rules={[{ required: true, message: 'Please insert plan days' }]}
              >
                <Input />
              </Form.Item> */}

              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: true, message: 'Please insert plan price' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item name="is_active" valuePropName="checked" label="Active Plan">
                <Switch />
              </Form.Item>

              <Form.Item
                name="is_default"
                valuePropName="checked"
                label={
                  <>
                    <span style={{ marginRight: 8 }}>Default Plan</span>
                    <Tooltip title="Setting this as default will disable the other default plan">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <Switch />
              </Form.Item>
            </Card>

            <Gaps />

            <ThumbnailUpload
              initialValue={{ thumbnail_url: initialValue?.thumbnail }}
              isLoading={isLoading}
              setCurrentImage={setCurrentImage}
              setImageChange={setImageChange}
              // optional={false}
            />
          </Col>

          {id !== 'new' && (
            <>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                <Card
                  bordered={false}
                  title="Available to user"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addUser}
                >
                  <UserSearch
                    searchKey="email"
                    name="add_user"
                    ignores={initialValue.users.map((activity) => activity.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Email',
                        dataIndex: 'email',
                        key: 'email',
                        sorter: true
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await PlanService.removeUser(id, row.id)
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue.users}
                    pagination={{
                      pageSize: 5
                    }}
                  />
                </Card>

                <Gaps />

                <Card
                  bordered={false}
                  title="Default activities"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addDefaultActivity}
                >
                  <ActivitySearch
                    searchKey="name"
                    name="add_default_activity"
                    ignores={initialValue.activities.map((activity) => activity.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await PlanService.removeActivity(id, row.id, {
                                    type: 'default'
                                  })
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue.activities}
                    pagination={{
                      pageSize: 5
                    }}
                  />
                </Card>

                <Gaps />

                <Card
                  bordered={false}
                  title="Library Activities"
                  bodyStyle={{ padding: '16px 16px 0 16px' }}
                  extra={addLibraryActivity}
                >
                  <ActivitySearch
                    searchKey="name"
                    name="add_library_activity"
                    ignores={initialValue.library_activities.map((activity) => activity.id)}
                  />

                  <Table
                    columns={[
                      {
                        title: 'Name',
                        dataIndex: 'name',
                        key: 'name',
                        sorter: true
                      },
                      {
                        title: 'Action',
                        dataIndex: 'action',
                        key: 'action',
                        render: (_, row) => {
                          return (
                            <Button
                              onClick={async () => {
                                try {
                                  await PlanService.removeActivity(id, row.id, {
                                    type: 'library'
                                  })
                                  refresh()
                                  message.success('Update successful')
                                } catch (error) {
                                  console.log(error)
                                  message.error('Update error ' + error.message)
                                }
                              }}
                            >
                              Delete
                            </Button>
                          )
                        }
                      }
                    ]}
                    dataSource={initialValue.library_activities}
                    pagination={{
                      pageSize: 5
                    }}
                  />
                </Card>

                {/* <Card bordered={false} title="Available to user">
                  <UserSearch mode="multiple" name="users" searchKey="email" />
                </Card>

                <Gaps />

                <Card bordered={false} title="Activities in library">
                  <ActivitySearch mode="multiple" searchKey="name" name="library_activities" />
                </Card>

                <Gaps />

                <Card bordered={false} title="Add Activity" extra={addActivity}>
                  <ActivitySearch searchKey="name" name="add_activity" />
                </Card>

                <Gaps />

                <Card bordered={false} title="Reorder Activities" extra={reorderActivity}>
                  <ReorderActivity keyId="plan_activity_id" />
                </Card> */}
              </Col>
            </>
          )}
        </Row>
      </Form>
    </>
  )
}

export default PlanForm
