import React, { useEffect, useState, useCallback } from 'react'
import { Html } from '@react-three/drei'
import { Button } from 'antd'
import { useSnapshot } from 'valtio'
// import screenfull from 'screenfull'
import ReactSlider from 'react-slider'
import { useUpdateEffect } from 'react-use'

// import player from '@iso/lib/store/player'
// import recorder from '../../recorderStore'
// import { secondsToHms } from '@iso/lib/helpers/utility'
import player from '../../lib/store/video_player'
import { secondsToHms } from '../../lib/utility'
import { Subtitles2D } from './Subtitles'
import VideoControl from './style'

// const Actions = () => {
//   const { playlist, playing } = useSnapshot(player)

//   if (playlist[playing].actions?.length > 0) {
//     if (playlist[playing].recording?.success === false && playlist[playing].recorded === true) {
//       return (
//         <>
//           {playlist[playing].actions.map(({ style, onClick }) => {
//             return (
//               <Button style={style} onClick={onClick}>
//                 CTA
//               </Button>
//             )
//           })}
//         </>
//       )
//     }
//   }

//   return null
// }

export const Controls = ({ videoRef, subtitles }) => {
  const { playing, isPlaying, currentTime, clipDuration } = useSnapshot(player)
  // const { isLoading } = useSnapshot(recorder)
  const [update, setUpdate] = useState(true)
  const [percentage, setPercentage] = useState(0)

  const togglePlayPause = () => {
    try {
      if (isPlaying === false) {
        videoRef.current.getInternalPlayer().play()
        player.isPlaying = !isPlaying
      } else {
        videoRef.current.getInternalPlayer().pause()
        player.isPlaying = !isPlaying
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const toggleFullScreen = async () => {
  //   const canvas = document.querySelector('#canvas')

  //   try {
  //     if (screenfull.isEnabled) {
  //       if (screenfull.isFullscreen) {
  //         screenfull.exit()

  //         if (isAndroid) {
  //           try {
  //             await ScreenOrientation.unlock()
  //           } catch (error) {
  //             if (error instanceof TypeError) {
  //               // alert('TypeError')

  //               try {
  //                 // eslint-disable-next-line no-restricted-globals
  //                 screen.orientation.unlock()
  //               } catch (error) {
  //                 alert('Cannot change orientation')
  //               }
  //             }
  //           }
  //         }
  //       } else {
  //         screenfull.request(canvas)

  //         if (isAndroid) {
  //           try {
  //             await ScreenOrientation.lock('landscape')
  //           } catch (error) {
  //             if (error instanceof TypeError) {
  //               // alert('TypeError')

  //               try {
  //                 // eslint-disable-next-line no-restricted-globals
  //                 screen.orientation.lock('landscape')
  //               } catch (error) {
  //                 alert('Cannot change orientation')
  //               }
  //             }
  //           }
  //         }
  //       }
  //     } else {
  //       // iPhones
  //       // use css to fullscreen, maybe hide all elements and make canvas 100vh/100vw
  //     }
  //   } catch (error) {
  //     alert(error)
  //   }
  // }

  const handleSliderStart = () => {
    setUpdate(false)
  }

  const handleSliderChange = useCallback((value) => {
    setPercentage(value)
  }, [])

  const handleSliderEnd = (value) => {
    setUpdate(true)
    videoRef.current.seekTo(value, 'seconds')
  }

  useEffect(() => {
    if (update) {
      setPercentage(currentTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  // useUpdateEffect(() => {
  //   // if playing changed, video switched, autoplay
  //   videoRef.current.getInternalPlayer().play()
  // }, [playing])

  useUpdateEffect(() => {
    const video = videoRef.current.getInternalPlayer()
    video.pause()
    video.oncanplaythrough = () => {
      video.play()
    }
  }, [playing])

  return (
    <Html
      // as="div" // Wrapping element (default: 'div')
      // prepend // Project content behind the canvas (default: false)
      // center // Adds a -50%/-50% css transform (default: false) [ignored in transform mode]
      fullscreen // Aligns to the upper-left corner, fills the screen (default:false) [ignored in transform mode]
      // distanceFactor={10} // If set (default: undefined), children will be scaled by this factor, and also by distance to a PerspectiveCamera / zoom by a OrthographicCamera.
      // zIndexRange={[100, 0]} // Z-order range (default=[16777271, 0])
      // portal={domnodeRef} // Reference to target container (default=undefined)
      // transform // If true, applies matrix3d transformations (default=false)
      // sprite // Renders as sprite, but only in transform mode (default=false)
      // calculatePosition={(el: Object3D, camera: Camera, size: { width: number; height: number }) => number[]} // Override default positioning function. (default=undefined) [ignored in transform mode]
      // occlude={[ref]} // Can be true or a Ref<Object3D>[], true occludes the entire scene (default: undefined)
      // onOcclude={(visible) => null} // Callback when the visibility changes (default: undefined)
      // {...groupProps} // All THREE.Group props are valid
      // {...divProps} // All HTMLDivElement props are valid
    >
      {/* {isLoading && <Spinner />} */}

      <Subtitles2D subtitles={subtitles} />

      <VideoControl>
        <div style={{ paddingBottom: '1%', textAlign: 'left' }}>
          <span color="white" sx={{ userSelect: 'none' }}>
            {secondsToHms(currentTime)}/{secondsToHms(clipDuration)}
          </span>
          <ReactSlider
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            step={0.1}
            min={0}
            max={clipDuration}
            value={percentage}
            onBeforeChange={handleSliderStart}
            onChange={handleSliderChange}
            onAfterChange={handleSliderEnd}
            onSliderClick={handleSliderChange}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button style={{ userSelect: 'none' }} onClick={togglePlayPause}>
            {isPlaying ? 'Pause' : 'Play'}
          </Button>

          {/* <Button style={{ userSelect: 'none' }} onClick={toggleFullScreen}>
            Full Screen
          </Button> */}
        </div>
      </VideoControl>

      {/* <Actions /> */}
    </Html>
  )
}
