import React, { useRef, useState } from 'react'
import { Typography } from 'antd'
import ReactPlayer from 'react-player'
import player from '../../lib/store/video_player'
import { getBase64 } from '../../lib/utility'
import VideoCanvas from './VideoCanvas'

const VideoPreview = ({ initialValue, isLoading, currentVideo, setVideoChange, subtitles }) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewVideo, setPreviewVideo] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [fileList, setFileList] = useState(null)
  const videoRef = useRef()

  //   useEffect(() => {
  //     if (initialValue?.video_url != null) {
  //       setFileList([
  //         {
  //           uid: initialValue?.video_url,
  //           name: `${initialValue?.title}`,
  //           status: 'done',
  //           url: initialValue?.video_url,
  //           src: initialValue?.video_url
  //         }
  //       ])
  //     }
  //   }, [initialValue])

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handleDuration = (duration) => {
    player.clipDuration = duration
  }

  const handleProgress = (progress) => {
    player.currentTime = progress.playedSeconds
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewVideo(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  // const handleRemove = (value) => {
  //   setCurrentVideo(null)
  // }

  // const handleUpload = async (file) => {

  //   const isLt100M = file.size / 1024 / 1024 < 30
  //   if (!isLt100M) {
  //     message.error('Video must be smaller than 30MB!')
  //     return false
  //   }
  //   else {
  //     setVideoChange(true)
  //     setCurrentVideo(file)
  //     message.success('Video finished uploading.')
  //   }

  // }

  return (
    <>
      {currentVideo?.video_url != null ? (
        <div
          style={{
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <div style={{ display: 'none' }}>
            <ReactPlayer
              ref={videoRef}
              url={currentVideo.video_url}
              playsInline
              controls
              progressInterval={300}
              onDuration={handleDuration}
              onProgress={handleProgress}
              width="100%"
              height="100%"
              config={{
                file: {
                  attributes: {
                    crossOrigin: 'true'
                  }
                }
              }}
            />
          </div>

          <div
            style={{
              width: '100%',
              height: 0,
              paddingBottom: '56.25%'
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0
            }}
          >
            <VideoCanvas videoRef={videoRef} subtitles={subtitles} />
          </div>
        </div>
      ) : (
        <Typography.Text>Please select a video</Typography.Text>
      )}
    </>
  )
}

export default VideoPreview
