import client from '../request'

const getAll = `/versions`

const get = (id) => {
  return `/versions/${id}`
}

const create = (data) => {
  return client.post('/versions', data)
}

const update = (id, data) => {
  return client.put(`/versions/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data?.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}
const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/versions/${id}`)
}

const batchRemove = (data) => {
  return client.post(`/versions/delete-batch`, data)
}

const VersionService = {
  getAll,
  get,
  create,
  update,
  toRow,
  remove,
  batchRemove,
  toPaginate
}

export default VersionService
