import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  Col,
  Form,
  Input,
  Row,
  message,
  InputNumber,
  Modal,
  Button,
  Upload,
  Switch,
  Tag
} from 'antd'
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons'

import ChapterService from '../../network/services/chapter'
import FileService from '../../network/services/file'

import Gaps from '../../components/Gaps'
import VideoSearch from '../../components/VideoSearch'
import ChapterSearch from '../../components/ChapterSearch'
import StorySearch from '../../components/StorySearch'

import { getBase64 } from '../../lib/utility'
import VideoService from '../../network/services/video'

const ChapterForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [previewVideoVisible, setPreviewVideoVisible] = useState(false)
  const [previewVideo, setPreviewVideo] = useState(null)
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewAudio, setPreviewAudio] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const [currentAudio, setCurrentAudio] = useState(null)
  const [currentAudioDuration, setCurrentAudioDuration] = useState(null)
  const [audioChange, setAudioChange] = useState(false)
  const [fileList, setFileList] = useState(null)
  const [audioFileName, setAudioFileName] = useState(null)
  const [filterChapters, setFilterChapters] = useState(null)

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    console.log(values)

    if (audioChange && currentAudio != null) {
      values['audio_url'] = currentAudio
    }

    values['audio_file_name'] = audioFileName
    values['audio_duration'] = currentAudioDuration

    if (initialValue?.id) {
      try {
        const { data: result } = await ChapterService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update chapter error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await ChapterService.create(values)
        if (result) {
          message.success('Create successful')
          navigate(`/dashboard/chapters/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create chapter error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  const handlePreview = async (file) => {
    console.log(file)
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }
    setPreviewAudio(file.url || file.preview)
    setPreviewVisible(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleRemove = (value) => {
    console.log('handle remove')
    console.log(value)
    setCurrentAudio(null)
  }

  const handleCancel = () => {
    setPreviewVisible(false)
  }

  const handleUpload = async (file) => {
    const isMp3 = file.type === 'audio/mpeg' || 'audio/wav'
    if (!isMp3) {
      message.error('You can only upload mp3 or wav file!')
      return false
    }
    const isLt20M = file.size / 1024 / 1024 < 20
    if (!isLt20M) {
      message.error('Audio must smaller than 20MB!')
      return false
    }

    try {
      const { data: upload } = await FileService.create(file, { type: 'audio' })
      if (upload.success === true) {
        // setCurrentVideo({ src: upload.data, url: upload.data, uid: file.uid })
        setCurrentAudio(upload.data)

        const audio = document.createElement('audio')
        audio.src = upload.data

        audio.onloadedmetadata = () => {
          // Remove some unnecessary decimals with toFixed
          setCurrentAudioDuration(audio.duration.toFixed(2))
          console.log(audio.duration.toFixed(2))
          // Wait for video duration to be calculated to satisfy this condition
          setAudioChange(true)
        }
      }
    } catch (error) {
      message.error(`Upload failed : ${error?.data?.statusText ?? error?.message ?? 'unknown'}`)
    }
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  console.log(initialValue)

  return (
    <>
      <Form
        form={form}
        name="new-chapter"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          chapter_type: initialValue == null ? 'automated' : initialValue.chapter_type,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
        onValuesChange={(values) => {
          if (Object.keys(values).includes('story_id')) {
            setFilterChapters(values['story_id'])
          }
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
            <Card bordered={false}>
              <Form.Item
                name="title"
                label="Title"
                rules={[{ required: true, message: 'Please insert chapter title' }]}
              >
                <Input />
              </Form.Item>

              {id !== 'new' && (
                <Form.Item
                  name="handle"
                  label="Handle"
                  rules={[{ required: true, message: 'Please insert handle' }]}
                >
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: true, message: 'Please insert chapter description' }]}
              >
                <Input.TextArea autoSize={{ minRows: 4 }} />
              </Form.Item>
            </Card>

            <Gaps />

            <Card>
              <Form.Item name="recording_start" label="Audio Record Start">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item name="recording_end" label="Audio Record End">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>

              <p>**Note: These are required if there are options & recordings are needed</p>
            </Card>

            <Gaps />

            <Card title="Options" bordered={false}>
              <Form.Item
                name="is_free_response"
                label={`Is free response?`}
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <p style={{ marginTop: 16, marginBottom: 0 }}>
                **Note: Ticking this will skip recording checking
              </p>
              <p>**Note: Options still need to be fill to determine next chapter</p>

              {['one', 'two', 'three', 'four', 'five', 'six'].map((item) => {
                return (
                  <>
                    <Form.Item name={`option_${item}`} label={`Option ${item} answer`}>
                      <Input />
                    </Form.Item>
                    <ChapterSearch
                      name={`option_${item}_chapter_id`}
                      label={`Option ${item} chapter`}
                      searchKey="title"
                      story={initialValue?.story_id ?? filterChapters}
                      ignore={id}
                      optional
                    />
                    <Form.Item name={`option_${item}_threshold`} label={`Option ${item} threshold`}>
                      <InputNumber style={{ width: '100%' }} />
                    </Form.Item>
                    <Gaps />
                  </>
                )
              })}
            </Card>
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
            <Card bordered={false}>
              <StorySearch
                label="Story"
                name="story_id"
                searchKey="title"
                rules={[{ required: true, message: 'Please select a story!' }]}
              />
            </Card>

            <Gaps />

            <Card
              title="Video"
              bordered={false}
              extra={
                <Button
                  onClick={async () => {
                    try {
                      const vidId = form.getFieldValue('video_id')
                      const { data: vid } = await VideoService.getByClient(vidId)
                      console.log(vid)
                      setPreviewVideo(vid)
                      setPreviewVideoVisible(true)
                    } catch (error) {
                      message.error('Could not find the video')
                    }
                  }}
                >
                  Preview
                </Button>
              }
            >
              <VideoSearch
                name="video_id"
                searchKey="title"
                rules={[{ required: true, message: 'Please select a video!' }]}
              />

              <Modal
                visible={previewVideoVisible}
                title={previewVideo?.data?.title}
                footer={null}
                onCancel={() => {
                  setPreviewVideoVisible(false)
                }}
              >
                <video src={previewVideo?.data?.video_url} controls style={{ width: '100%' }} />
              </Modal>
            </Card>

            <Gaps />

            <Card title="Audio" bordered={false}>
              <>
                <Upload
                  accept="audio/mpeg , audio/wav"
                  beforeUpload={(file) => {
                    handleUpload(file)
                    return false
                  }}
                  fileList={fileList}
                  onPreview={handlePreview}
                  onRemove={handleRemove}
                  onChange={(value) => {
                    console.log(value.fileList[0])
                    setFileList(value.fileList)
                    if (value.fileList.length === 1) {
                      setAudioFileName(value.fileList[0].name)
                    }
                  }}
                  // previewFile={(file) => {
                  //   console.log('Your upload file:', file)
                  //   // Your process logic. Here we just mock to the same file
                  //   return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
                  //     method: 'POST',
                  //     body: file
                  //   })
                  //     .then((res) => res.json())
                  //     .then(({ thumbnail }) => thumbnail)
                  // }}
                >
                  <Button icon={isLoading ? <LoadingOutlined /> : <UploadOutlined />}>
                    Click to Upload
                  </Button>
                </Upload>

                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <audio src={previewAudio} controls></audio>
                </Modal>
              </>
            </Card>

            <Gaps />

            <Card title="Fallback Chapter" bordered={false}>
              <ChapterSearch
                name={`fallback_chapter_id`}
                searchKey="title"
                story={initialValue?.story_id ?? filterChapters}
                ignore={id}
              />
            </Card>

            <Gaps />

            <Card title="Is Last Chapter" bordered={false}>
              {initialValue?.last_video ? (
                <Tag color="success">Yes</Tag>
              ) : (
                <Tag color="error">No</Tag>
              )}
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ChapterForm
