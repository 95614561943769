import React, { useEffect, useState } from 'react'
import { Image, Typography, Empty, Spin, Space, Button, message } from 'antd'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { proxy, useSnapshot } from 'valtio'
import { useInterval } from 'react-use'
import useSWR, { mutate } from 'swr'

import PlanService from '../network/services/plan'
import SubscriptionService from '../network/services/subscription'
import UserGroupService from '../network/services/user_group'
import { serialize } from '../network/request'
import { usePositionReorder } from '../lib/hooks/usePositionReorder'
import { useMeasurePosition } from '../lib/hooks/useMeasurePosition'

export const reorderActivityState = proxy({ mutating: true, items: [] })

export const ReorderActivity = ({ keyId }) => {
  const { id } = useParams()
  const { mutating } = useSnapshot(reorderActivityState)

  // use this to preload
  const { data: response, error } = useSWR(
    keyId === 'plan_activity_id'
      ? serialize(PlanService.getActivity(id))
      : keyId === 'user_group_activity_id'
      ? serialize(UserGroupService.getActivity(id))
      : serialize(SubscriptionService.getActivity(id))
  )

  if (error) {
    console.log(error)
    return <Empty description={error?.message ?? 'Please try again later'} />
  }

  if (!response && id !== 'new') {
    return <Spin />
  }

  if (!mutating) {
    return <ActivityWrapper keyId={keyId} activities={response.data} />
  }

  return <MutatingSpin keyId={keyId} />
}

const MutatingSpin = ({ keyId }) => {
  const { id } = useParams()
  const { mutating } = useSnapshot(reorderActivityState)

  useInterval(
    () => {
      reorderActivityState.mutating = false
    },
    mutating ? 300 : null
  )

  useEffect(() => {
    if (keyId === 'plan_activity_id') {
      mutate(serialize(PlanService.getActivity(id)))
    } else {
      mutate(serialize(SubscriptionService.getActivity(id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Spin />
}

const ActivityWrapper = ({ keyId, activities }) => {
  const [items, updatePosition, updateOrder] = usePositionReorder(activities ?? [])

  useEffect(() => {
    reorderActivityState.items = items
  }, [items])

  return (
    <>
      {items?.map((activity, i) => {
        return (
          <ActivityItem
            keyId={keyId}
            i={i}
            key={activity[keyId]}
            activity={activity}
            updatePosition={updatePosition}
            updateOrder={updateOrder}
          />
        )
      })}
    </>
  )
}

const ActivityItem = ({ keyId, activity, i, updatePosition, updateOrder }) => {
  const [isDragging, setDragging] = useState(false)
  const { id: parentId } = useParams()
  const ref = useMeasurePosition((pos) => updatePosition(i, pos))

  return (
    <li
      style={{
        padding: 0,
        marginBottom: 24,
        zIndex: isDragging ? 3 : 1,
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <motion.div
        ref={ref}
        layout
        initial={false}
        style={{
          backgroundColor: '#f1f1f1',
          border: '1px solid #d9d9d9',
          padding: '8px 16px',
          flex: 1
        }}
        whileHover={{
          scale: 1.03
        }}
        whileTap={{
          scale: 1.12
        }}
        drag="y"
        onDragStart={() => setDragging(true)}
        onDragEnd={() => setDragging(false)}
        onViewportBoxUpdate={(_viewportBox, delta) => {
          isDragging && updateOrder(i, delta.y.translate)
        }}
      >
        <Space size={20}>
          <Typography.Text>{i + 1}.</Typography.Text>
          {activity?.thumbnail_url != null && (
            <Image src={activity?.thumbnail_url} preview={false} draggable={false} />
          )}
          <Space direction="vertical">
            <Typography.Text>{activity?.name}</Typography.Text>
            <Typography.Text>{activity?.short_description}</Typography.Text>
            <Typography.Text>{activity?.playlist?.title}</Typography.Text>
          </Space>
        </Space>
      </motion.div>
      <Button
        type="button"
        style={{ marginLeft: 20 }}
        onClick={async () => {
          try {
            if (keyId === 'plan_activity_id') {
              const { data: result } = await PlanService.removeActivity(parentId, activity[keyId])
              if (result.success) {
                message.success('Activity removed')
                mutate(serialize(PlanService.getActivity(parentId)))
                reorderActivityState.mutating = true
              }
            } else {
              const { data: result } = await SubscriptionService.removeActivity(
                parentId,
                activity[keyId]
              )
              if (result.success) {
                message.success('Activity removed')
                mutate(serialize(SubscriptionService.getActivity(parentId)))
                reorderActivityState.mutating = true
              }
            }
          } catch (error) {
            console.log(error)
          }
        }}
      >
        Delete
      </Button>
    </li>
  )
}
