import React from 'react'
import { motion } from 'framer-motion'
import { useSnapshot } from 'valtio'

import player from '../../lib/store/video_player'

const SubtitleText = ({ enter, exit, before, after, text, font_size, color, newLine }) => {
  const { currentTime } = useSnapshot(player)
  const initialAnim = { opacity: 0 }
  const beforeAnim = { opacity: 0.2 }
  const afterAnim = { opacity: 1 }

  const anim = React.useMemo(() => {
    if (currentTime > exit) {
      return 'removed'
    }

    if (currentTime > after) {
      return 'afterAnim'
    }

    if (currentTime > before) {
      return 'beforeAnim'
    }

    if (currentTime > enter) {
      return 'initialAnim'
    }

    return 'removed'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTime])

  return (
    <>
      {anim !== 'removed' && (
        <>
          {newLine && <br style={{ userSelect: 'none' }} />}
          <motion.span
            style={{
              fontSize: font_size ? `${font_size}px` : '1.5em',
              marginRight: '0.2em',
              userSelect: 'none',
              color: color ?? '#000000'
            }}
            variants={{ initialAnim, beforeAnim, afterAnim }}
            initial="initialAnim"
            animate={anim}
          >
            {text}
          </motion.span>
        </>
      )}
    </>
  )
}

export default SubtitleText
