import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, Col, Form, Input, Row, Tag, message } from 'antd'
import ReactJson from 'react-json-view'
import { DateTime } from 'luxon'

import RecordingService from '../../network/services/recording'

import Gaps from '../../components/Gaps'
import { usePositionReorder } from '../../lib/hooks/usePositionReorder'
import ReactPlayer from 'react-player'

const RecordingForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [items, updatePosition, updateOrder] = usePositionReorder(initialValue?.activities ?? [])

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    let itemIds = items?.map((e) => e.id)
    let newValue = { ...values }

    if (values.activities?.length !== items?.length) {
      // use activities from form
    } else if (itemIds !== values.activities) {
      // ordering has been changed
      // use activities from reordered list
      newValue.activities = items.map((item) => item.id)
    }

    if (initialValue?.id) {
      try {
        const { data: result } = await RecordingService.update(initialValue.id, newValue)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update recording error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await RecordingService.create(newValue)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/recordings/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create recording error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-recording"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          created_at:
            initialValue.created_at != null
              ? DateTime.fromISO(initialValue.created_at).toFormat('dd/MM/yyyy HH:mm:ss')
              : '',
          activities: initialValue?.activities?.map((item) => item.id),
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item label="Recording For First Chapter">
                {initialValue.chapter?.story?.default_chapter_id === initialValue.chapter_id ? (
                  <Tag color="success">Yes</Tag>
                ) : (
                  <Tag color="error">No</Tag>
                )}
              </Form.Item>

              <Form.Item label="Recording For Last Chapter">
                {initialValue.is_last_recording ? (
                  <Tag color="success">Yes</Tag>
                ) : (
                  <Tag color="error">No</Tag>
                )}
              </Form.Item>

              <Form.Item name="category" label="Option Answer">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="hypothesis" label="Recording Answer">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="hypothesis_score" label="Score">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="language" label="Language">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="created_at" label="Date">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['chapter', 'title']} label="Chapter">
                <Input readOnly />
              </Form.Item>

              <Form.Item name={['activity', 'name']} label="Activity">
                <Input readOnly />
              </Form.Item>

              <Form.Item label="Recording">
                <ReactPlayer url={initialValue?.recording?.file_url} controls height={50} />
              </Form.Item>

              <Form.Item name="calibrations" label="Calibrations">
                <Input readOnly />
              </Form.Item>

              <Form.Item name="volumes" label="Actual Volume">
                <Input readOnly />
              </Form.Item>
            </Card>

            <Gaps />
          </Col>

          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <p>Result</p>
              <ReactJson src={initialValue?.raw_result ?? {}} />
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default RecordingForm
