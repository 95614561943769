import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'

import SubtitleText from './SubtitleText'

export const Subtitles2D = ({ subtitles }) => {
  const [top, setTop] = useState(subtitles[0]?.top)
  const [left, setLeft] = useState(subtitles[0]?.left)

  useEffect(() => {
    setTop(subtitles[0]?.top)
    setLeft(subtitles[0]?.left)
    // This seems to be buggy, last time I tried it was working perfectly fine now the position seems to be not updating after clicking generate.
  }, [subtitles])

  if (subtitles?.length > 0) {
    return (
      <motion.div initial={{ y: top ?? 65, x: left ?? 50 }}>
        {subtitles?.map((props, index) => {
          return <SubtitleText {...props} key={index} />
        })}
      </motion.div>
    )
  }

  return <></>
}
