import client from '../request'

const getAll = `/subtitles`

const get = (id) => {
  return `/subtitles/${id}`
}

const create = (data) => {
  return client.post('/subtitles', data)
}

const update = (id, data) => {
  return client.put(`/subtitles/${id}`, data)
}

const toRow = (data) => {
  if (data?.data?.length > 0) {
    return data.data.map((element) => {
      return {
        ...element,
        key: element.id
      }
    })
  }

  return []
}

const toPaginate = (data) => {
  return {
    total: data?.meta?.total ?? 0
  }
}

const remove = (id) => {
  return client.delete(`/subtitles/${id}`)
}

const SubtitleService = {
  getAll,
  get,
  create,
  update,
  toRow,
  toPaginate,
  remove
}

export default SubtitleService
