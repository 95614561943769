import { proxy } from 'valtio'

const player = proxy({
  ref: null,
  currentTime: 0,
  clipDuration: 0,
  playing: 0,
  isPlaying: false,
  // debug
  canRetry: true
})

export default player
