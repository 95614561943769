import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, Col, Form, Row, message, Input, InputNumber, Switch } from 'antd'

import UserSearch from '../../components/UserSearch'
import ActivitySearch from '../../components/ActivitySearch'
import SettingService from '../../network/services/setting'

const SettingForm = ({
  form = null,
  initialValue = null,
  isLoading = null,
  setIsLoading = null,
  refresh
}) => {
  const navigate = useNavigate()

  const handleSubmit = async (values) => {
    if (isLoading) return
    setIsLoading(true)

    if (initialValue?.id) {
      try {
        const { data: result } = await SettingService.update(initialValue.id, values)
        if (result.success) {
          message.success('Update successful')
          refresh()
        }
      } catch (error) {
        message.error('Update setting error ' + error.message)
      }
    } else {
      try {
        const { data: result } = await SettingService.create(values)
        if (result.success) {
          message.success('Create successful')
          navigate(`/dashboard/settings/${result.data.id}`)
        }
      } catch (error) {
        message.error('Create setting error ' + error.message)
      }
    }

    setIsLoading(false)
  }

  useEffect(() => {
    form.resetFields()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue])

  return (
    <>
      <Form
        form={form}
        name="new-setting"
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          ...initialValue,
          enabled: initialValue == null ? false : initialValue.enabled,
          condition_match: initialValue == null ? 'all' : initialValue.condition_match,
          sort_order: initialValue == null ? 'ascending' : initialValue.sort_order
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
            <Card bordered={false}>
              <Form.Item name="name" label="Name">
                <Input />
              </Form.Item>

              <Form.Item name="value" label="Value">
                <Input />
              </Form.Item>

              <Form.Item name="enabled" valuePropName="checked" label="Enabled">
                <Switch />
              </Form.Item>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default SettingForm
