import client from '../request'

const create = (data, config) => {
  const formData = new FormData()
  formData.append('file', data)
  if (config?.type) {
    formData.append('type', config.type)
  }

  return client.post('/upload', formData)
}

const createFile = (data) => {
  const formData = new FormData()
  formData.append('file', data)
  formData.append('type', 'image')
  return client.post('/upload-file', formData)
}

const FileService = {
  create,
  createFile
}

export default FileService
